<template>
  <v-bottom-sheet v-model="show"  persistent>
    <v-btn
      outlined
      color="primary"
      class="close-btn"
      icon
      @click="close"
    >
      <v-icon color="primary">mdi-close</v-icon>
    </v-btn>
    <v-card class="rounded-t-xl pa-6" style="background-color: #f4f4fa">

      <v-card-title class="text-h6 font-weight-bold d-flex justify-center items-center mb-3">
        {{ lote?.id ? "Editar Lote" : "Agregar Lote" }}
      </v-card-title>

      <v-card-text>
        <!-- General Information -->
        <h3 class="text-body-1 mb-2 black--text font-weight-bold">
          General
        </h3>
        <v-row class="mb-4" dense>
          <v-col cols="6" md="6">
            <pat-input
              label="Lote"
              placeholder="OD 240547"
              v-model="lote.lote"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" md="6">
            <pat-input
              type="date"
              label="Caducidad"
              placeholder="21/Dic/2024"
              v-model="lote.caducidad"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <!-- Existencias por ubicación -->
        <h3 class="text-body-1 mb-2 black--text font-weight-bold">
          Existencias
        </h3>
        <div v-for="(sucursal, index) in lote.sucursales" :key="index" class="mb-5">
          <v-row dense class="align-center mb-0">
            <v-col cols="8" md="8" class="mb-0">
              <pat-input
                type="number"
                :label="sucursal.nombre || 'Existencia'"
                placeholder="11"
                v-model="sucursal.existencia"
                :rules="[rules.required]"
                class="mt-1 mb-0"
              />
            </v-col>
            <v-col cols="4" md="4" class="mb-0">
              <pat-input
                type="number"
                label="Reserva"
                placeholder="2"
                v-model="sucursal.reserva"
                :rules="[rules.required]"
                class="mt-1 mb-0"
              />
            </v-col>
          </v-row>

          <!-- Ubicación Desplegable -->
          <v-expansion-panels flat class="mt-2">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <p class="black--text text-body-1 font-weight-medium ma-0">
                  Ubicación
                  <span class="font-weight-regular">(Opcional)</span>
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="4">
                    <pat-input
                      label="No. Rack"
                      placeholder="Ej. B"
                      v-model="sucursal.rack"
                    />
                  </v-col>
                  <v-col cols="4">
                    <pat-input
                      label="Fila"
                      placeholder="Ej. 11"
                      v-model="sucursal.fila"
                    />
                  </v-col>
                  <v-col cols="4">
                    <pat-input
                      label="Columna"
                      placeholder="Ej. 4"
                      v-model="sucursal.columna"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <hr
            v-if="index !== lote.sucursales.length - 1"
            class="black--text my-4"
            style="border-color: black; border-width: 0.5px"
          />
        </div>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions>
        <v-btn
          class="py-4"
          color="primary"
          dark
          block
          rounded
          :disabled="!valid"
          @click="save"
        >
          {{ lote?.id ? "Guardar Cambios" : "Crear Lote" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import PatInput from './PatInput.vue';

export default {
  name: "LoteForm",
  components: {
    PatInput
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    lote: {
      type: Object,
      default: () => ({
        id: null,
        lote: "",
        caducidad: "",
        sucursales: [
          {
            id: null,
            existencia: 0,
            reserva: 0,
            rack: "",
            fila: "",
            columna: "",
            nombre: "Existencia"
          },
        ],
      }),
    },
  },
  data() {
    return {
      valid: false,
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    save() {
      // Validar que los campos requeridos estén llenos
      if (this.lote.lote && this.lote.caducidad) {
        const loteToSave = {
          ...this.lote,
          id: this.lote.id || Date.now(), // Generar ID temporal si es nuevo
        };
        this.$emit("save", loteToSave);
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  overflow-y: auto;
}

/* Personalización del expansion panel */
.v-expansion-panels {
  background: transparent !important;
}

.v-expansion-panel {
  background: transparent !important;
  box-shadow: none !important;
}

.v-expansion-panel-header {
  padding: 0 !important;
  min-height: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 12px 0 !important;
}

.close-btn {
  border: 2px solid !important;
  background-color: white !important;
  z-index: 1;
}
</style>
