import { render, staticRenderFns } from "./PasswordRecovery.vue?vue&type=template&id=6a3672b2&scoped=true"
import script from "./PasswordRecovery.vue?vue&type=script&lang=js"
export * from "./PasswordRecovery.vue?vue&type=script&lang=js"
import style0 from "./PasswordRecovery.vue?vue&type=style&index=0&id=6a3672b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3672b2",
  null
  
)

export default component.exports