<template>
  <v-container>
    <!-- Pantalla si no existen clientes aún -->
    <v-row
      v-if="isClientsEmpty"
      justify="center"
      class="d-flex justify-center align-center text-center"
      style="min-height: 80vh"
    >
      <v-col cols="12" md="8" class="px-10">
        <v-img
          src="@/assets/clients.png"
          contain
          max-height="120"
          alt="Clientes"
          class="mb-3"
        ></v-img>

        <p class="text-body-2 my-5 px-8">
          Da de alta tus clientes para poder asignarlos a una venta, hacerles
          llegar promociones especiales y ofrecerles descuentos.
        </p>

        <v-btn
          rounded
          block
          outlined
          color="primary"
          class="py-6 border-3 text-none text-body-1 font-weight-bold"
          style="border-width: 3px"
          @click="addClient"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar cliente
        </v-btn>
      </v-col>
    </v-row>

    <!-- Lista de clientes existentes -->
    <v-row v-else class="mt-5 px-5">
      <!-- Botón para agregar un cliente -->
      <v-col cols="12" md="4" class="mb-5">
        <v-btn
          rounded
          block
          outlined
          color="primary"
          class="py-6 border-3 text-none text-body-1 font-weight-bold"
          style="border-width: 3px"
          @click="addClient"
        >
          + Agregar cliente
        </v-btn>
      </v-col>

      <!-- Tarjetas de clientes -->
      <v-col cols="12" md="4" v-for="(client, index) in clients" :key="index">
        <v-card-text class="pa-2 bgcards rounded-xl" style="width: 100%">
          <v-list dense class="bgcards pa-0 ma-0">
            <v-list-item class="pa-0 ma-0">
              <!-- Avatar del cliente -->
              <v-avatar class="mr-3" color="deep-purple lighten-5">
                <v-icon size="35" color="deep-purple lighten-1">
                  mdi-account-outline
                </v-icon>
              </v-avatar>

              <!-- Información del cliente -->
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ client.apodo }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span
                    class="black--text"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: block;
                    "
                  >
                    <strong>{{ client.nombre_completo }}</strong>
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ client.telefono_contacto }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ client.precio_aplicable }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-btn
                text
                color="primary text-body-1"
                @click="editClient(client.id)"
              >
                Editar
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getClients } from "../services/auth";

export default {
  data() {
    return {
      clients: [],
    };
  },
  computed: {
    isClientsEmpty() {
      return this.clients.length === 0;
    },
  },
  methods: {
    addClient() {
      this.$router.push({ name: "create-client" });
    },
    editClient(id) {
      this.$router.push({ name: "edit-client", params: { id } });
    },
  },
  async created() {
    const response = await getClients();
    if (response.success) {
      this.clients = response.clientes;
    } else {
      this.$toast({
        message: response.message,
        color: "error",
        timeout: 2000,
      });
    }
  },
  mounted() {
    this.$emit("update:titulo", "Clientes");
    this.$emit("update:onBack", () => {
      this.$router.push({ name: "home" });
    });
  },
};
</script>
