<template>
  <v-container class="pa-5">
    <div class="mx-2 mt-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Sucursales -->
        <pat-select
          class="mb-4"
          placeholder="Sucursales"
          :items="sucursales"
          :rules="[rules.required]"
          v-model="selectedSucursal"
        />
      </v-form>

      <div class="my-5">
        <p class="text-caption ma-0">Categoría | {{ product.categoria }}</p>
        <p class="text-h5 ma-0">
          <strong>{{ product.nombre_producto }}</strong>
        </p>
        <p class="my-1">
          {{ product.unidad_venta }} {{ product.contenido_caja }} -
          {{ product.cantidad }} {{ product.cantidad_unidad }}
        </p>
        <p class="text-caption ma-0">
          Código de barras: <strong>{{ product.codigo_barras }}</strong>
        </p>
        <p class="ma-0 text-body-2">
          SKU: <strong>{{ product.sku }}</strong>
        </p>
        <p class="ma-0 text-body-2">
          Marca: <strong>{{ product.marca }}</strong>
        </p>
      </div>

      <v-card class="pa-3 bgcards rounded-xl" elevation="2" style="width: 100%">
        <v-card-text class="text-start black--text">
          <v-row class="d-flex align-items-start">
            <!-- Encabezado dividido en tres columnas -->
            <v-col cols="12" class="d-flex justify-space-between">
              <div class="d-flex flex-column align-items-start">
                Precio
                <strong class="primary--text text-h6"
                  >${{ product.precio_sin_iva }}</strong
                >
                <p class="text-caption textgrey--text">
                  IVA {{ product.iva }}%
                </p>
              </div>
              <div class="d-flex flex-column align-items-center">
                Existencias
                <strong class="green--text text-h6">{{
                  product.cantidad
                }}</strong>
                <p class="textgrey--text text-caption">
                  {{ product.unidad_venta
                  }}{{ product.cantidad > 1 ? "s" : "" }}
                </p>
              </div>
              <div class="d-flex flex-column align-items-end">
                Estatus
                <p
                  :class="product.es_activo ? 'purple--text' : 'text-grey'"
                  class="font-weight-bold text-h6"
                >
                  {{ product.es_activo ? "Activo" : "Inactivo" }}
                </p>
              </div>
            </v-col>
            <!-- Imagen del producto -->
            <v-col
              cols="12"
              class="d-flex justify-center pa-0 rounded-xl"
              style="
                width: 100%;
                height: 100%;
                border: 2px solid gray;
                border-radius: 8px;
              "
            >
              <div class="pa-0 rounded-xl" style="width: 100%; height: 100%">
                <v-img :src="product.imagen" class="rounded-xl pa-3" contain />
              </div>
            </v-col>

            <!-- Detalles del producto -->
            <v-col cols="12" class="d-flex flex-column align-items-start py-2">
              <p class="ma-0 pa-0 text-caption black--text">Descripción</p>
              <p class="ma-0 pa-0 text-caption black--text">
                <strong>{{ product.descripcion }}</strong>
              </p>
              <p class="d-flex flex-column ma-0 pa-0 text-caption mt-5">
                Producto controlado
                <strong>{{ product.es_controlado ? "Sí" : "No" }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-btn
                rounded
                block
                outlined
                color="primary"
                class="py-6 border-3 mt-5"
              >
                <v-icon left>mdi-printer-outline</v-icon>
                Imprimir código
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column items-center justify-center"
            >
              <v-btn
                text
                class="text-none primary--text my-2 text-body-1 font-weight-bold text-decoration-underline"
                style="width: 100%"
              >
                Editar producto
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!--tarjeta: Detalle de existencia -->
      <v-card class="mt-4 pa-3 bgcards rounded-xl" elevation="2">
        <v-card-title class="text-body-1 font-weight-bold"
          >Detalle de existencia</v-card-title
        >
        <v-card-text class="black--text">
          <v-row
            class="ma-0 pa-0"
            v-for="(lote, indice) in lotes"
            :key="indice"
          >
            <v-col cols="6" class="pa-0">
              <p class="ma-0 black--text">Lote</p>
              <p class="black--text font-weight-bold ma-0">{{ lote.nombre }}</p>
              <p
                class="font-weight-bold ma-0"
                :class="
                  cantidadBaja(lote.cantidad) ? 'red--text' : 'green--text'
                "
              >
                {{ lote.cantidad }} {{ product.unidad_venta
                }}{{ lote.cantidad > 1 ? "s" : "" }}
              </p>
            </v-col>
            <v-col cols="6" class="text-right pa-0">
              <p class="ma-0 black--text">Caducidad</p>
              <p
                class="font-weight-bold ma-0"
                :class="
                  fechaCercanaAVencimiento(lote.vencimiento)
                    ? 'red--text'
                    : 'black--text'
                "
              >
                {{ lote.vencimiento }}
              </p>
            </v-col>
          </v-row>

          <h3 class="mt-6 black--text">Precios</h3>

          <v-row>
            <v-col
              cols="12"
              class="ma-0 pb-0"
              v-for="(item, index) in product.precios_venta"
              :key="index"
            >
              <p
                class="black--text ma-0 d-flex justify-space-between align-center"
              >
                <span>{{ item.tipo }}</span>
                <strong class="text-h6">${{ item.precio.toFixed(2) }}</strong>
              </p>

              <p
                class="text-caption ma-0 d-flex justify-space-between align-center textgrey--text"
              >
                Margen: {{ calcularMargen(item.precio) }}%
                <span>Ganancia: ${{ calcularGanancia(item.precio) }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            class="text-none primary--text my-2 text-body-1 font-weight-bold text-decoration-underline"
            style="width: 100%"
          >
            Editar existencia y precios
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="gradient-card mt-5 rounded-xl pa-3" elevation="2">
        <v-card-text class="pa-0">
          <v-row class="d-flex align-center ma-0 pa-0">
            <!-- Icono -->
            <v-col cols="3" class="d-flex justify-center ma-0 pa-0">
              <v-icon size="45" color="white">mdi-cart-heart</v-icon>
            </v-col>

            <!-- Contenido -->
            <v-col cols="9" class="ma-0 pa-0">
              <v-row class="d-flex ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <span class="white--text"> ¡Hey cómpralo en Pat por! </span>
                </v-col>
                <v-col cols="6" class="d-flex ma-0 pa-0">
                  <span class="white--text font-weight-bold text-h6">
                    $110.00
                  </span>
                </v-col>
                <v-col cols="6" class="d-flex justify-end ma-0 pa-0">
                  <v-btn text color="white">Comprar &gt;</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="my-5 pa-3 bgcards rounded-xl" elevation="2">
        <v-card-title class="text-body-1 font-weight-bold">
          Últimos movimientos
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Encabezado de movimientos -->
            <v-col
              cols="12"
              class="d-flex justify-space-between align-center py-0"
            >
              <p class="ma-0 black--text">Edición de precio</p>
              <p
                class="font-weight-bold green--text ma-0 px-4 py-1 rounded-xl text-body-1"
                style="
                  border: 1px solid green;
                  background-color: white;
                  width: 70px;
                  text-align: center;
                "
              >
                +20
              </p>
            </v-col>
            <v-col
              cols="12"
              class="text-caption grey--text py-0"
              style="margin-top: -5px"
            >
              <p class="ma-0">11/Nov/24 10:00 am | Delia M...</p>
              <ul class="my-0 py-0">
                <li class="black--text">
                  Este precio se decidió dar solo a los clientes frecuentes.
                </li>
              </ul>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-space-between align-center py-0 mt-3"
            >
              <p class="ma-0 primary--text">Venta #4</p>
              <p
                class="font-weight-bold red--text ma-0 px-4 py-1 rounded-xl text-body-1"
                style="
                  border: 1px solid red;
                  background-color: white;
                  width: 70px;
                  text-align: center;
                "
              >
                -2
              </p>
            </v-col>
            <v-col
              cols="12"
              class="text-caption grey--text py-0"
              style="margin-top: -5px"
            >
              <p class="ma-0">19/Sep/24 12:03 pm | Delia M...</p>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-space-between align-center py-0"
            >
              <p class="ma-0 black--text">Edición de precio</p>
              <p
                class="font-weight-bold green--text ma-0 px-4 py-1 rounded-xl text-body-1"
                style="
                  border: 1px solid green;
                  background-color: white;
                  width: 70px;
                  text-align: center;
                "
              >
                +10
              </p>
            </v-col>
            <v-col
              cols="12"
              class="text-caption grey--text py-0"
              style="margin-top: -5px"
            >
              <p class="ma-0">02/Nov/24 10:00 am | Delia M...</p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <!-- Botón para más movimientos -->
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-btn
                rounded
                block
                outlined
                color="primary"
                class="py-6 border-3 mt-5"
              >
                <v-icon left>mdi-format-list-bulleted-square</v-icon>
                Ver más movimientos
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { getSucursales } from "../services/auth";

export default {
  data() {
    return {
      step: 1,
      valid: false,
      sucursales: [], // Aquí puedes cargar las sucursales
      selectedSucursal: null,
      product: {
        imagen: "", // URL de la imagen del producto
        nombre_producto: "", // Nombre del producto
        descripcion: "", // Descripción del producto
        precio_sin_iva: 0, // Precio del producto sin IVA
        iva: 16, // IVA aplicado
        es_activo: false, // Estado del producto
        cantidad: 0, // Cantidad en inventario
        min: 0, // Cantidad mínima
        unidad_venta: "Caja", // Unidad de venta
        contenido_caja: 10, // Cantidad de unidades por caja
        cantidad_unidad: "tabletas", // Tipo de unidad de venta
        codigo_barras: "", // Código de barras
        sku: "", // SKU
        marca: "", // Marca del producto
        categoria: "Medicamentos", // Categoría del producto
        es_controlado: false, // Si el producto es controlado
        precio_compra: 0,
        precios_venta: [
          { tipo: "Precio público", precio: 20 },
          { tipo: "Precio mayoreo", precio: 15 },
        ],
      },

      lotes: [
        { nombre: "ABCD 3222", cantidad: 2, vencimiento: "2025/01/21" },
        { nombre: "OD 240547", cantidad: 20, vencimiento: "2025/12/21" },
      ],
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
      },
    };
  },

  async created() {
    const sucursalesResponse = await getSucursales();
    if (sucursalesResponse.success) {
      this.sucursales = sucursalesResponse.sucursales.map((sucursal) => ({
        text: sucursal.nombre,
        value: sucursal.id,
      }));
    } else {
      this.$toast({
        message: "Ocurrió un error al obtener las sucursales",
        color: "error",
        timeout: 2000,
      });
      console.error("Error al obtener sucursales");
    }

    this.loadProduct();
  },

  methods: {
    loadProduct() {
      this.product = {
        imagen: require("@/assets/jaloma.png"),
        nombre_producto: "Medley Paracetamol",
        descripcion: "Caja de medicamentos",
        precio_sin_iva: 100.0,
        iva: 16,
        es_activo: true,
        cantidad: 500,
        min: 5,
        unidad_venta: "Caja",
        contenido_caja: 10,
        cantidad_unidad: "500 MG",
        codigo_barras: "7 5011159 580359",
        sku: "00 PARA MED",
        marca: "Medley",
        categoria: "Medicamentos",
        es_controlado: false,
        precio_compra: 10,
        precios_venta: [
          { tipo: "Precio público", precio: 20 },
          { tipo: "Precio mayoreo", precio: 15 },
        ],
      };
    },
    fechaCercanaAVencimiento(vencimiento) {
      const fechaVencimiento = new Date(vencimiento);
      const fechaActual = new Date();
      const diferenciaTiempo = fechaVencimiento - fechaActual;
      const diferenciaDias = diferenciaTiempo / (1000 * 60 * 60 * 24);
      // Si la diferencia es menor o igual a 30 días, está cercana al vencimiento
      return diferenciaDias <= 30 && diferenciaDias >= 0;
    },
    cantidadBaja(cantidad) {
      return cantidad < 5;
    },
    calcularMargen(precioVenta) {
      if (this.product.precio_compra === 0) return 0; // Evitar división por cero
      const margen =
        ((precioVenta - this.product.precio_compra) /
          this.product.precio_compra) *
        100;
      return margen; // Redondear a dos decimales
    },
    calcularGanancia(precioVenta) {
      return (precioVenta - this.product.precio_compra).toFixed(2);
    },
  },
};
</script>

<style>
.gradient-card {
  background: linear-gradient(170deg, #5e90f4, #8e47ec);
}
</style>
