<template>
  <v-app>
    <v-app-bar app dark class="menu-background">
      <v-btn icon @click="handleBack">
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view :titulo.sync="titulo"  :onBack.sync="onBack"/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "InfoBarLayout",
  props: {
  },
  data: () => ({
    titulo: "Detalle",
    onBack: null,
  }),
  methods: {
    handleBack() {
      if (this.onBack) {
        this.onBack();
        this.onBack = null;
      } else {
        this.$router.back();
      }
    },
  },
  watch: {
    $route(to) {
      this.titulo = to.meta.title;
      this.onBack = null;
    },
  },
};
</script>

<style>
.menu-background {
  background: linear-gradient(170deg, #5e90f4, #8e47ec);
}
</style>
