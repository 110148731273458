<template>
  <v-app>
    <v-container class="px-10">
      <v-row>
        <v-col cols="12" class="d-flex justify-center mb-5 mt-15">
          <v-img
            :src="require('@/assets/pat-pos-logo.png')"
            alt="PAT POS Logo"
            class="mx-auto"
            contain
            max-width="146px"
          />
        </v-col>

        <v-card
          class="rounded-xl pa-2"
          style="background-color: #f4f4fa"
          min-height="380px"
        >
          <v-card-title
            class="d-flex justify-center align-center text-h5 font-weight-bold"
          >
            <slot name="title">
              {{ rol === 1 ? "Bienvenido a PAT" : "Valida tu cuenta" }}
            </slot>
          </v-card-title>

          <v-card-text class="text-center">
            <slot name="description">
              <p v-if="rol === 1">
                Para comenzar a configurar tu cuenta, ingresa el código que
                recibiste por SMS al número <strong>{{ phoneNumber }}</strong> .
              </p>
              <p v-if="rol === 2">
                Ingresa el código que tu empleador compartió contigo.
              </p>
            </slot>

            <div class="my-4">
              <p class="text-start mb-0">Código</p>
              <div class="d-flex justify-center">
                <v-otp-input
                  autofocus
                  color="light-green"
                  :length="6"
                  type="number"
                  placeholder="0"
                  v-model="otp"
                  :disabled="loading"
                  @finish="onFinish"
                  class="custom-otp-input mb-12"
                ></v-otp-input>
                <v-overlay absolute :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </div>

            <p v-if="codeError" class="red--text font-weight-bold">
              Por favor, ingrese el código completo.
            </p>

            <p
              v-if="rol === 1"
              class="blue--text text-decoration-underline font-weight-bold"
            >
              Volver a enviar código
            </p>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              class="py-6"
              @click="handleContinue"
              :color="isCodeComplete ? 'primary' : 'grey darken-1'"
              dark
              block
              rounded
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { verifyOtp, validateOtp} from "../services/auth";

export default {
  name: "verification-code",
  data() {
    return {
      loading: false,
      otp: "",
      codeError: false,
    };
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    rol: {
      type: Number,
      required: false,
    },
    isRecovery: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    isCodeComplete() {
      return this.otp.length === 6; // Verificamos si el OTP tiene 6 caracteres
    },
  },
  methods: {
    async validate(value) {
      console.log("Validating OTP recovery");
      this.loading = true;
      const response = await validateOtp(this.phoneNumber, value); // Verificación del OTP
      this.loading = false;
      if (response.success) {
        this.$emit("otp-validated", value); 
      } else {
        this.$toast({
          message: "Código incorrecto. Por favor, inténtalo de nuevo.",
          color: "error",
          timeout: 2000,
        });
      }

    },
    handleContinue() {
      if (this.isCodeComplete) {
        this.onFinish(this.otp);
      } else {
        this.codeError = true;
      }
    },
    async onFinish(value) {
      console.log("onfinish");
      this.loading = true;
      if(this.isRecovery){
        this.validate(value);
        return;
      }
      const verificado = await verifyOtp(this.phoneNumber, value); // Verificación del OTP
      this.loading = false;
      if (verificado) {
        this.$emit("otp-validated", true); // Emitir evento si la verificación fue exitosa
      } else {
        this.$toast({
          message: "Código incorrecto. Por favor, inténtalo de nuevo.",
          color: "error",
          timeout: 2000,
        });
      }
    },
    // async onFinish(value) {
    //   this.loading = true;

    //   // Verificación simplificada para pruebas
    //   const verificado = value === "111111"; // Comprobamos si el OTP es igual a 111111

    //   this.loading = false;

    //   if (verificado) {
    //     this.$emit("otp-validated", true); // Emitir evento si la verificación fue exitosa
    //   } else {
    //     this.$toast({
    //       message: "Código incorrecto. Por favor, inténtalo de nuevo.",
    //       color: "error",
    //       timeout: 2000,
    //     });
    //   }
    // },
  },
};
</script>

<style>
.custom-otp-input {
  display: flex;
  flex-wrap: nowrap;
}

.custom-otp-input .v-input {
  width: 0.45rem !important;
}

.custom-otp-input .v-input__control {
  border-radius: 15px !important;
}

.custom-otp-input .v-text-field__slot input {
  text-align: center;
  font-size: 18px !important;
}
</style>
