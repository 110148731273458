import { defineStore } from "pinia";
import { getUserDetails } from "../services/auth";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    token: localStorage.getItem("token") || null,
    selectedSucursal: null,
  }),
  actions: {
    async getUser() {
      const response = await getUserDetails();
      if (response.success) {
        this.setUser(response.user);
      }
    },
    setUser(userData) {
      this.user = userData;
    },
    setSucursal(sucursalId) {
      this.selectedSucursal = sucursalId;
      localStorage.setItem("selectedSucursal", sucursalId);
    },
    logout() {
      this.user = null;
      this.token = null;
      this.selectedSucursal = null;
      localStorage.removeItem("token");
      localStorage.removeItem("selectedSucursal");
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user?.nombre,
    userName: (state) => state.user?.nombre || "Invitado",
    getCurrentSucursal: (state) => state.selectedSucursal,
  },
});
