import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Inicio.vue"),
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/sales",
    name: "sales",
    component: () => import("../views/Sales.vue"),
  },
  {
    path: "/new-product",
    name: "new-product",
    component: () => import("../views/NewProductG.vue"),
    meta: {
      layout: "info-bar",
      title: "Nuevo producto",
    },
  },
  {
    path: "/new-product-g",
    name: "new-product-g",
    component: () => import("../views/NewProductG.vue"),
    meta: {
      layout: "info-bar",
      title: "Nuevo producto",
    },
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/Producto.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("../views/Productos.vue"),
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => import("../views/AddProducts.vue"),
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/detail-inventory",
    name: "detail-inventory",
    component: () => import("../views/DetailInventory.vue"),
  },
  {
    path: "/detail-product",
    name: "detail-product",
    component: () => import("../views/DetailProduct.vue"),
    meta: {
      layout: "info-bar",
      title: "Inventario",
    },
  },
  {
    path: "/bussiness",
    name: "bussiness",
    component: () => import("../views/Negocio.vue"),
  },
  {
    path: "/owner",
    name: "owner",
    component: () => import("../views/Propietario.vue"),
  },
  {
    path: "/",
    name: "create-account",
    component: () => import("../views/CreateAccount.vue"),
  },
  {
    path: "/config-account",
    name: "config-account",
    component: () => import("../views/ConfAccount.vue"),
  },
  {
    path: "/configuration",
    name: "configuration",
    component: () => import("../views/Configuration.vue"),
    meta: {
      layout: "info-bar",
      title: "Configuración",
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/ChangePassword.vue"),
    meta: {
      layout: "info-bar",
      title: "Cambiar contraseña",
    },
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () => import("../views/PasswordRecovery.vue"),
  },
  {
    path: "/change-chain-data",
    name: "change-chain-data",
    component: () => import("../views/ChangeChainData.vue"),
    meta: {
      layout: "info-bar",
      title: "Cambiar datos de la cadena",
    },
  },
  {
    path: "/branches",
    name: "branches",
    component: () => import("../views/Branches.vue"),
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/branches/create",
    name: "create-branch",
    component: () => import("../views/CreateBranch.vue"),
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/branches/edit/:id",
    name: "edit-branch",
    component: () => import("../views/CreateBranch.vue"),
    props: true,
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/branch-no-lot/:productId?",
    name: "branch-no-lot",
    component: () => import("../views/BranchNoLot.vue"),
    props: true,
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/employees",
    name: "employees",
    component: () => import("../views/Employees.vue"),
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/employees/create",
    name: "create-employee",
    component: () => import("../views/CreateEmployee.vue"),
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/employees/edit/:id/:index",
    name: "edit-employee",
    component: () => import("../views/CreateEmployee.vue"),
    meta: {
      layout: "info-bar",
    },
  },
  {
    path: "/config-account-employee",
    name: "config-account-employee",
    component: () => import("../views/ConfAccountEmployee.vue"),
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/Clients.vue"),
    meta: {
      layout: "info-bar",
      title: "Clientes",
    },
  },
  {
    path: "/clients/create",
    name: "create-client",
    component: () => import("../views/CreateClient.vue"),
    meta: {
      layout: "info-bar",
      title: "Crear cliente",
    },
  },
  {
    path: "/clients/edit/:id",
    name: "edit-client",
    component: () => import("../views/CreateClient.vue"),
    meta: {
      layout: "info-bar",
      title: "Editar cliente",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
