<template>
  <v-app>
    <v-container fluid class="white">
      <v-row v-if="step == 1">
        <v-col cols="12" class="d-flex justify-center mt-16 pt-10">
          <v-img
            :src="require('@/assets/pat-pos-logo.png')"
            alt="PAT POS Logo"
            class="mx-auto"
            contain
            max-width="146px"
          />
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center">
          <v-card
            class="pa-5 bgcards rounded-xl mx-5"
            elevation="2"
            min-height="416px"
          >
            <v-card-title class="text-center d-flex justify-center">
              <h3 class="headline mx-auto font-weight-bold">
                <span>Bienvenido</span>
              </h3>
              <p class="text-body-2">Ingresa tus datos para iniciar sesión</p>
            </v-card-title>

            <v-card-text>
              <v-form v-model="valid">
                <div>
                  <pat-input
                    label="Número celular"
                    placeholder="55 5555 5555"
                    v-model="username"
                    :maxlength="10"
                    :isNumber="true"
                    :rules="[rules.required]"
                  />

                  <br />
                  <pat-input
                    class="mt-4"
                    label="Contraseña"
                    type="password"
                    placeholder="********"
                    v-model="password"
                    :rules="[rules.required]"
                  />
                </div>

                <br /><br />

                <v-btn
                  color="primary"
                  block
                  @click="forgotPassword"
                  rounded
                  outlined
                  class="text-none text-body-1 font-weight-bold py-6"
                >
                  ¿Olvidaste tu contraseña?
                </v-btn>
                <br />
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  block
                  @click="submitForm"
                  rounded
                  class="text-none text-body-1 font-weight-bold py-6"
                >
                  Continuar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-center">
          <p class="primary--text text-decoration-underline font-weight-bold">
            <span @click="configAccount">No tengo una cuenta PAT</span>
          </p>
        </v-col>
      </v-row>

      <v-row v-if="step == 2">
        <verification-code
          :phoneNumber="username"
          :rol="1"
          @otp-validated="handleOtpValidated"
        />
      </v-row>
      <v-row v-if="step == 3">
        <verification-code
          v-if="step == 2"
          :phoneNumber="username"
          :rol="2"
          :pathName="'home'"
        />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { login } from "@/services/auth";

export default {
  data() {
    return {
      valid: false,
      step: 1,
      username: "",
      password: "",
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        phone: (value) => {
          const phoneRegex = /^\d{10}$/;
          return (
            phoneRegex.test(value) ||
            "El número de teléfono debe tener 10 dígitos."
          );
        },
      },
    };
  },
  methods: {
    handleOtpValidated(isValid) {
      if (isValid) {
        this.$router.push({ name: "home" });
      } else {
        console.log("Código incorrecto, intenta de nuevo");
      }
    },
    async submitForm() {
      if (this.valid) {
        let logged = await login(this.username, this.password);
        switch (logged) {
          case 1:
            this.$router.push({ name: "home" });
            break;
          case 2:
            this.step = 2;
            break;
          case 3:
            this.step = 3;
            break;
          case 0:
            this.$toast({
              message: "Usuario o contraseña incorrectos.",
              color: "error",
              timeout: 2000,
            });
        }
      }
    },
    configAccount() {
      this.$router.push({ name: "create-account" });
    },
    forgotPassword() {
      this.$router.push({ name: "recover-password" });
    },
  },
};
</script>

<style></style>
