<template>
  <v-app class="transparent-app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "TransparentLayout",
};
</script>

<style>
.transparent-app {
  background: transparent;
}
</style>
