<template>
  <v-container fluid>
    <!-- Formulario -->
    <v-form ref="form" v-model="valid">
      <!-- Productos -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Productos
          </v-card-title>
          <v-row class="no-gutters d-flex align-items-center justify-center">
            <v-col
              cols="3"
              class="d-flex flex-column align-items-center justify-center"
            >
              <v-img :src="productos.image" style="height: 74px; width: 67px" />
            </v-col>
            <v-col
              cols="6"
              class="d-flex flex-column align-items-center justify-center"
            >
              <p class="ma-0 pa-0 text-caption black--text font-weight-bold">
                {{ productos.name }}
              </p>
              <p class="ma-0 pa-0 text-caption black--text">
                {{ productos.desc }}
              </p>
              <strong class="primary--text" style="font-size: 20px">
                {{ productos.precio }}
              </strong>
            </v-col>

            <v-col
              cols="3"
              class="d-flex flex-column align-center justify-center"
            >
              <div
                class="rounded-lg pa-1"
                style="
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  background-color: white;
                  border: 1px solid red;
                  text-align: center;
                "
              >
                <p class="ma-0 caption red--text">
                  {{ productos.cantidad }}
                </p>
                <p class="ma-0 ml-1 caption red--text">
                  {{ productos.unidadVenta
                  }}{{
                    productos.cantidad > 1 || productos.cantidad == 0 ? "s" : ""
                  }}
                </p>
              </div>

              <p class="purple--text caption text-center mt-2">
                <strong>Activo</strong>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Existencia y precios  -->
      <v-row class="px-5 pt-5 w-100" no-gutters>
        <div class="d-flex justify-between" style="width: 100%">
          <h2
            class="font-weight-bold mb-2"
            style="font-size: 24px; margin: 0; flex-grow: 1"
          >
            Existencia y precios
          </h2>
        </div>
        <p>Ingresa la información del producto</p>
        <v-checkbox
          label="Utilizar Lotes y Caducidades"
          class="px-3 mb-3"
          color="purple"
        ></v-checkbox>
      </v-row>
      <!-- Detalle de existencia -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Detalle de existencia
          </v-card-title>
          <div v-for="(lot, index) in lotes" :key="index">
            <v-row class="no-gutters d-flex align-items-center justify-center">
              <v-col
                cols="6"
                class="d-flex flex-column align-items-center justify-center"
              >
                <p class="ma-0 pa-0 text-caption black--text">Lote</p>
                <p class="ma-0 pa-0 text-caption black--text font-weight-bold">
                  {{ lot.name }}
                </p>
                <strong class="red--text" style="font-size: 15px">
                  {{ lot.cantidad }} cajas
                </strong>
              </v-col>
              <v-col
                cols="3"
                class="d-flex flex-column align-items-center justify-center"
              >
                <p class="ma-0 pa-0 text-caption black--text">Caducidad</p>
                <p class="ma-0 pa-0 text-caption black--text font-weight-bold">
                  {{ lot.caducidad }}
                </p>
                <strong class="primary--text" style="font-size: 20px"> </strong>
              </v-col>

              <v-col
                cols="2"
                class="d-flex flex-column align-center justify-center pa-4"
              >
                <v-btn icon>
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>

                <p class="caption text-center mt-2">
                  <v-btn
                    text
                    class="primary--text text-none mt-n4 text-decoration-underline"
                    @click="handleEdit"
                  >
                    Editar
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <!-- agregar otro lote -->
      <div class="mt-4 px-5">
        <v-btn
          block
          text
          class="font-weight-bold primary--text text-none mt-n3"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar otro lote
        </v-btn>
      </div>
      <!-- Precio de compra -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Precio de compra
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6" class="pa-0">
                <div class="d-flex align-center">
                  <span class="text-body-1 font-weight-medium ma-0 pa-0 mr-2"
                    >Costo</span
                  >
                  <v-select
                    v-model="costType"
                    :items="costOptions"
                    class="ml-0"
                    density="compact"
                    flat
                  ></v-select>
                </div>
              </v-col>

              <v-col cols="12" class="pa-0 mt-2">
                <pat-input
                  placeholder="0.00"
                  prefix="$"
                  v-model="costValue"
                  outlined
                  shaped
                  dense
                  hide-details
                >
                  <template #append>
                    <span class="d-flex flex-row caption">por caja</span>
                  </template>
                </pat-input>

                <p class="caption mt-1 black--text">Incluyendo impuestos</p>
              </v-col>
            </v-row>

            <p class="d-flex justify-space-between black--text">
              Costo unitario <span>${{ costValue }} por caja</span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Precio de venta -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Precio de venta
          </v-card-title>
          <v-card-text>
            <div v-for="(price, index) in prices" :key="index" class="mb-5">
              <div class="d-flex justify-space-between mb-2 mr-2">
                <label class="d-flex align-center font-weight-bold mb-3">
                  Público
                </label>
                <v-icon icon color="grey" class="ml-1" style="font-size: 18px">
                  mdi-alert-circle
                </v-icon>
              </div>

              <v-col cols="12" class="pa-0 mt-2">
                <pat-input
                  prefix="$"
                  v-model="price.publicPrice"
                  placeholder="0.00"
                  @input="calculateProfit(index)"
                >
                  <template #append>
                    <span class="d-flex flex-row caption text-grey--text"
                      >por caja</span
                    >
                  </template>
                </pat-input>
              </v-col>

              <div class="d-flex justify-space-between black--text mt-2">
                <p>Margen: {{ price.marginPercentage }}%</p>
                <p>Ganancia: $ {{ price.profitAmount }} por caja</p>
              </div>
            </div>
            <div v-for="(mayoreo, index) in precioma" :key="index" class="mb-5">
              <div class="d-flex justify-space-between mb-2 mr-2">
                <label class="d-flex align-center font-weight-bold mb-3">
                  Mayoreo
                </label>
              </div>
              <v-col cols="12" class="pa-0 mt-2">
                <pat-input
                  prefix="$"
                  v-model="mayoreo.publicPrice"
                  placeholder="0.00"
                  @input="calculateProfit(index)"
                >
                  <template #append>
                    <span class="d-flex flex-row caption text-grey--text"
                      >por caja</span
                    >
                  </template>
                </pat-input>
              </v-col>

              <div class="d-flex justify-space-between black--text mt-2">
                <p>Margen: {{ mayoreo.marginPercentage }}%</p>
                <p>Ganancia: $ {{ mayoreo.profitAmount }} por caja</p>
              </div>
            </div>
          </v-card-text>
          <!-- agregar o editar precios -->
          <v-btn
            block
            text
            class="font-weight-bold primary--text text-none mt-n3"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar o editar precios
          </v-btn>
        </v-card>
      </v-col>

      <!-- Guardar cambios -->
      <div class="mt-4 px-5">
        <v-btn class="py-6" color="primary" block @click="submitForm" rounded>
          Guardar cambios
        </v-btn>
      </div>
      <!-- Cancelar cambios -->
      <div class="mt-8 px-5" v-if="show == !valid">
        <v-btn
          block
          text
          class="font-weight-bold primary--text text-none mt-n3 text-decoration-underline"
          @click="inventario"
        >
          Cancelar Cambios
        </v-btn>
      </div>
    </v-form>
    <!-- Modal de guardar cambioss -->
    <div class="text-center">
      <v-dialog
        v-model="addExit"
        max-width="400"
        persistent
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: 0px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeAddExit"
            >
              <v-icon color="primary ">mdi-close</v-icon>
            </v-btn>
            <!-- Imagen y título -->
            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
                padding-bottom: 24px;
              "
            >
              <v-icon
                contain
                max-height="100"
                max-width="100"
                color="green"
                size="80"
                class="mb-4 mx-auto"
                >mdi-check-decagram-outline
              </v-icon>
              <p
                class="font-weight-medium pa-0 ma-0 mt-2 green--text"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100%;
                  text-align: center;
                "
              >
                Edición exitosa
              </p>
              <v-col
                class="d-flex flex-column align-items-center justify-center"
              >
                <p
                  class="ma-0 pa-0 text-body-1 black--text font-weight-bold"
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                  "
                >
                  {{ productos.name }}
                </p>
                <p
                  class="ma- pa-0 d-flex flex-column align-items-center justify-center"
                >
                  <v-img
                    :src="productos.image"
                    class="mb-2 mx-auto"
                    contain
                    max-height="100"
                    max-width="100"
                  />
                </p>
              </v-col>
            </v-card-title>

            <v-card-actions
              class="d-flex flex-column items-center align-center"
            >
              <v-btn
                @click="saveProduct"
                class="py-4"
                color="primary"
                dark
                block
                rounded
                >Continuar editando</v-btn
              >

              <v-col cols="12" md="8" class="d-flex justify-center mt-3">
                <p
                  class="primary--text font-weight-bold"
                  style="cursor: pointer"
                >
                  Imprimir código
                </p>
              </v-col>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
    <!-- Modal de motivo de edición precio -->
    <div class="text-center">
      <v-dialog
        v-model="edit"
        max-width="500"
        persistent
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: 0px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeEdit"
            >
              <v-icon color="primary ">mdi-close</v-icon>
            </v-btn>
            <!-- Imagen y título -->
            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
                padding-bottom: 24px;
              "
            >
              <p
                class="ma-0 pa-0 text-body black--text font-weight-bold"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100%;
                  text-align: center;
                "
              >
                Motivo edición de precio
              </p>
            </v-card-title>
            <v-card-text class="text-start black--text">
              <pat-select
                label="Motivo"
                class="mb-5"
                v-model="motivo"
                :items="motivos"
              />

              <pat-input
                label="Comentario (Opcional)"
                textarea
                placeholder="Agrega un detalle del motivo de la edición"
              >
              </pat-input>
            </v-card-text>

            <v-card-actions
              class="d-flex flex-column items-center align-center"
            >
              <v-btn
                @click="editConf"
                class="py-4"
                color="primary"
                dark
                block
                rounded
                >Confirmar edición</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog
        v-model="dialogLotes"
        max-width="400"
        persistent
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: -10px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeDialogTermsAndconditions"
            >
              <v-icon color="primary pa-0 ma-0">mdi-close</v-icon>
            </v-btn>

            <v-card-title
              class="text-h6 font-weight-bold d-flex justify-center items-center mb-3"
            >
              Editar Lote
            </v-card-title>

            <v-card-text>
              <!-- General Information -->
              <h3 class="text-body-1 mb-2 black--text font-weight-bold">
                General
              </h3>
              <v-row class="mb-4" dense>
                <v-col cols="6" md="6">
                  <pat-input
                    label="Lote"
                    placeholder="OD 240547"
                    v-model="lote.nombre"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <pat-input
                    type="date"
                    label="Caducidad"
                    placeholder="21/Dic/2024"
                    v-model="lote.fecha_expiracion"
                  />
                </v-col>
              </v-row>

              <!-- Existencias por ubicación -->
              <h3 class="text-body-1 mb-2 black--text font-weight-bold">
                Existencias
              </h3>
              <div
                v-for="(location, index) in lote.ubicaciones"
                :key="index"
                class="mb-5"
              >
                <v-row dense class="align-center mb-0">
                  <v-col cols="8" md="8" class="mb-0">
                    <pat-input
                      type="number"
                      :label="location.name"
                      placeholder="11"
                      v-model="location.stock"
                      class="mt-1 mb-0"
                    />
                  </v-col>
                  <v-col cols="4" md="4" class="mb-0">
                    <pat-input
                      type="number"
                      label="Reserva"
                      placeholder="2"
                      v-model="location.reserve"
                      class="mt-1 mb-0"
                    />
                  </v-col>
                </v-row>

                <v-row dense class="align-center">
                  <v-col cols="12" class="pa-0">
                    <div class="d-flex align-center">
                      <p
                        class="black--text text-body-1 font-weight-medium ma-0 mr-2"
                        style="white-space: nowrap"
                      >
                        Ubicación
                        <span class="font-weight-regular">(Opcional)</span>
                      </p>
                      <v-select
                        v-model="costType"
                        :items="ubicacionOpciones"
                        class="ml-0"
                        dense
                        flat
                        style="max-width: 100%"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>

                <hr
                  v-if="index !== lote.ubicaciones.length - 1"
                  class="black--text mb-4"
                  style="border-color: black; border-width: 0.5px"
                />
              </div>
            </v-card-text>

            <!-- Actions -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="py-4"
                color="primary"
                dark
                block
                rounded
                @click="saveBatch"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      addExit: false,
      edit: false,
      show: false,
      valid: false,
      dialogLotes: false,
      lote: {
        nombre: "OD 240547",
        fecha_expiracion: "2024-12-21",
        ubicaciones: [
          { name: "La Divina", stock: 11, reserve: 2, customLocation: "" },
          {
            name: "La Divina Oriente",
            stock: 5,
            reserve: 1,
            customLocation: "",
          },
          { name: "La Divina Sur", stock: 17, reserve: 2, customLocation: "" },
        ],
      },
      motivo: "Corrección",
      motivos: [
        "Corrección",
        "Conteo",
        "Expiración",
        "Robo o pérdida",
        "Deterioro",
        "Regalo",
        "Traspaso",
      ],
      costOptions: ["neto", "bruto"],
      ubicacionOpciones: ["La divina norte", "La divina sur"],
      costType: "neto",
      costValue: "100.00",
      prices: [
        { publicPrice: "200.00", marginPercentage: "50", profitAmount: "100" },
      ],
      precioma: [
        { publicPrice: "150.00", marginPercentage: "33", profitAmount: "50" },
      ],
      productos: {
        name: "Jaloma Aceite para Bebé",
        image: require("@/assets/jaloma.png"),
        desc: "Botella - 120 ML",
        precio: "$28.00",
        cantidad: "0",
        unidadVenta: "botella",
      },

      lotes: [
        {
          name: "ABCD 3222",
          cantidad: "2",
          caducidad: "21/Abr/2024",
        },
        {
          name: "OD 24047",
          cantidad: "12",
          caducidad: "21/Dic/2025",
        },
      ],
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
    };
  },
  methods: {
    inventario() {
      this.$router.push({ name: "inventory" });
    },
    submitForm() {
      if (this.valid) {
        this.addExit = true;
      }
    },
    closeAddExit() {
      this.addExit = false;
    },
    saveProduct() {
      this.addExit = false;
      console.log("Guardando producto...");
      console.log(this.product);
    },
    editModal() {
      if (this.valid) {
        this.edit = true;
      }
    },
    closeEdit() {
      this.edit = false;
    },
    editConf() {
      this.edit = false;
    },
    saveBatch() {
      // Lógica para guardar el lote
      console.log("Batch saved:", this.lote);
      this.dialogLotes = false;
    },
    handleEdit() {
      this.dialogLotes = true;
    },
  },
};
</script>
<style scoped>
.custom-dialog-content {
  overflow: visible !important;
}
.custom-dialog-overlay {
  overflow: visible !important;
}
.v-text-field__details {
  display: none;
}
.v-select {
  max-width: 100%; /* Asegura que el select no se desborde */
  overflow: hidden; /* Evita que los contenidos se salgan */
  text-overflow: ellipsis; /* Trunca el texto si es necesario */
}
.v-select input {
  box-sizing: border-box; /* Ajusta el ancho para evitar desbordes */
}
</style>
