<template>
  <v-container fluid>
    <!-- Título y descripción -->
    <v-form v-model="valid">
      <v-row class="px-5 pt-5 w-100" no-gutters>
        <div class="d-flex justify-between" style="width: 100%">
          <h2
            class="font-weight-bold"
            style="font-size: 24px; margin: 0; flex-grow: 1"
          >
            Existencia y precios
          </h2>
        </div>
        <p>Ingresa la información del producto</p>
        <v-checkbox
          v-model="product.batchesAndExpiration"
          label="Utilizar lotes y caducidades"
          :true-value="true"
          :false-value="false"
          class="ma-0 pa-0"
        />
      </v-row>

      <!-- Formulario de Existencias -->
      <template v-if="!product.batchesAndExpiration">
        <!-- Formulario sin lotes -->
        <v-col cols="12" md="12" class="d-flex">
          <v-card
            class="pa-4 bgcards rounded-xl"
            elevation="2"
            style="width: 100%"
          >
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <pat-input
                    type="number"
                    label="La Divina"
                    placeholder="0"
                    v-model="product.boxes"
                    :rules="[rules.required]"
                    class="mb-4"
                  >
                    <template #append>
                      <span class="d-flex flex-row caption">cajas</span>
                    </template>
                  </pat-input>
                </v-col>
                <v-col cols="4">
                  <pat-input
                    type="number"
                    label="Reserva"
                    placeholder="0"
                    v-model="product.booking"
                    :rules="[rules.required]"
                    class="mb-4"
                  />
                </v-col>
              </v-row>

              <p class="text-body-1">
                <span class="font-weight-bold">Ubicación </span>(Opcional)
              </p>
              <v-row>
                <v-col cols="4">
                  <pat-input
                    label="No. Rack"
                    placeholder="Ej. B"
                    v-model="product.noRack"
                  />
                </v-col>

                <v-col cols="4">
                  <pat-input
                    label="Fila"
                    placeholder="Ej. 11"
                    v-model="product.row"
                  />
                </v-col>

                <v-col cols="4">
                  <pat-input
                    label="Columna"
                    placeholder="Ej. 4"
                    v-model="product.column"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </template>

      <template v-else>
        <!-- Formulario con lotes -->
        <v-col cols="12" md="12" class="d-flex">
          <v-card
            class="pa-4 bgcards rounded-xl"
            elevation="2"
            style="width: 100%"
          >
            <v-card-title class="d-flex justify-space-between align-center">
              <span class="text-h6 font-weight-bold">Detalle de existencia</span>
              <v-btn
                text
                color="primary"
                class="text-decoration-underline"
                @click="addLote()"
              >
                <v-icon left>mdi-plus</v-icon>
                Agregar lote
              </v-btn>
            </v-card-title>

            <div v-if="lotes.length === 0" class="text-center pa-4">
              <p class="grey--text">No hay lotes registrados</p>
            </div>

            <div v-else v-for="(lot, index) in lotes" :key="index">
              <v-row class="no-gutters d-flex align-items-center justify-center">
                <v-col
                  cols="6"
                  class="d-flex flex-column align-items-center justify-center"
                >
                  <p class="ma-0 pa-0 text-caption black--text">Lote</p>
                  <p class="ma-0 pa-0 text-caption black--text font-weight-bold">
                    {{ lot.name }}
                  </p>
                  <strong class="red--text" style="font-size: 15px">
                    {{ lot.cantidad }} cajas
                  </strong>
                </v-col>
                <v-col
                  cols="3"
                  class="d-flex flex-column align-items-center justify-center"
                >
                  <p class="ma-0 pa-0 text-caption black--text">Caducidad</p>
                  <p class="ma-0 pa-0 text-caption black--text font-weight-bold">
                    {{ lot.caducidad }}
                  </p>
                  <strong class="primary--text" style="font-size: 20px"> </strong>
                </v-col>

                <v-col
                  cols="2"
                  class="d-flex flex-column align-center justify-center pa-4"
                >
                  <v-btn icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <p class="caption text-center mt-2">
                    <v-btn
                      text
                      class="primary--text text-none mt-n4 text-decoration-underline"
                      @click="handleEdit"
                    >
                      Editar
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </template>

      <!-- Componente LoteForm -->
      <lote-form
        :show="showLoteForm"
        :lote="currentLote"
        @save="saveLote"
        @update:show="closeLoteForm"
      />

      <!-- Formulario de Precio de Compra -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold"
            >Precio de compra</v-card-title
          >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6" class="pa-0">
                <div class="d-flex align-center">
                  <span class="text-body-1 font-weight-medium ma-0 pa-0 mr-2"
                    >Costo</span
                  >
                  <v-select
                    v-model="product.costType"
                    :items="costOptions"
                    class="ml-0"
                    density="compact"
                    flat
                    :rules="[rules.required]"
                  ></v-select>
                </div>
              </v-col>

              <v-col cols="12" class="pa-0 mt-2">
                <pat-input
                  placeholder="0.00"
                  prefix="$"
                  v-model="product.costValue"
                  outlined
                  shaped
                  dense
                  hide-details
                  :rules="[rules.required]"
                >
                  <template #append>
                    <span class="d-flex flex-row caption">por caja</span>
                  </template>
                </pat-input>

                <p class="caption mt-1 black--text">Incluyendo impuestos</p>
              </v-col>
            </v-row>

            <p class="d-flex justify-space-between black--text">
              Costo unitario <span>${{ product.costValue }} por caja</span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Formulario de Precio de Venta -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold"
            >Precio de venta</v-card-title
          >
          <v-card-text>
            <div
              v-for="(price, index) in product.prices"
              :key="index"
              class="mb-5"
            >
              <div class="d-flex justify-space-between mb-2">
                <label class="d-flex align-center font-weight-bold mb-3">
                  Público
                </label>

                <v-btn
                  v-if="index > 0"
                  icon
                  color="red"
                  class="ml-3"
                  @click="removeVariant(index)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>

              <div class="d-flex align-center">
                <pat-input
                  prefix="$"
                  v-model="price.publicPrice"
                  placeholder="0.00"
                  :rules="[rules.required]"
                  @input="calculateProfit(index)"
                >
                  <template #append>
                    <span class="d-flex flex-row caption text-grey--text"
                      >por caja</span
                    >
                  </template>
                </pat-input>
              </div>

              <div class="d-flex justify-space-between black--text mt-2">
                <p>Margen: {{ price.marginPercentage }}%</p>
                <p>Ganancia: $ {{ price.profitAmount }} por caja</p>
              </div>
            </div>

            <v-col cols="12">
              <v-btn
                text
                color="primary"
                class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
                @click="addVariant"
              >
                <v-icon left color="primary">mdi-plus</v-icon>
                <span class="text-decoration-underline"
                  >Agregar otro precio</span
                >
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Botón de Guardar -->
      <v-card-actions class="justify-center">
        <v-btn
          :disabled="!valid"
          class="py-6"
          @click="submitForm"
          :color="valid ? 'primary' : 'grey darken-1'"
          block
          rounded
        >
          Guardar existencia
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import LoteForm from '@/components/LoteForm.vue';
import { getSucursales } from "@/services/auth";

export default {
  components: {
    LoteForm
  },

  props: {
    productId: {
      type: [String, Number],
      default: null
    }
  },
  
  data() {
    return {
      valid: false,
      product: {
        batchesAndExpiration: null,
        boxes: "",
        booking: "",
        noRack: "",
        row: "",
        column: "",
        costType: "neto",
        costValue: "",
        prices: [{ publicPrice: "", marginPercentage: "", profitAmount: "" }],
        profitAmount: 0,
      },
      costOptions: ["neto", "bruto", "Opción 3"],
      // Aquí se corrige la regla para que retorne un booleano o string
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
      showLoteForm: false,
      currentLote: {
        id: null,
        lote: "",
        caducidad: "",
        sucursales: [],
      },
      lotes: [],
      sucursales: [], // Array para almacenar las sucursales
    };
  },

  async created() {
    if (this.productId) {
      console.log('Producto ID recibido:', this.productId);
      await this.loadProductInfo();
    }
    await this.loadSucursales();
  },

  methods: {
    async loadSucursales() {
      try {
        const response = await getSucursales();
        if (response.success) {
          this.sucursales = response.sucursales.map(sucursal => ({
            id: sucursal.id,
            nombre: sucursal.nombre
          }));
        }
      } catch (error) {
        console.error('Error cargando sucursales:', error);
      }
    },

    closeLoteForm() {
      this.showLoteForm = false;
      this.currentLote = {
        id: null,
        lote: "",
        caducidad: "",
        sucursales: [],
      };
    },

    addLote() {
      this.currentLote = {
        id: null,
        lote: "",
        caducidad: "",
        sucursales: this.sucursales.map(sucursal => ({
          id: sucursal.id,
          nombre: sucursal.nombre,
          existencia: "0",
          reserva: "0",
          rack: "",
          fila: "",
          columna: "",
        })),
      };
      this.showLoteForm = true;
    },
    async loadProductInfo() {
      // Aquí puedes cargar la información del producto si es necesario
      try {
        // Implementar lógica para cargar info del producto
      } catch (error) {
        console.error('Error cargando información del producto:', error);
      }
    },
    submitForm() {
      console.log("Form data:", this.product);
    },
    addVariant() {
      this.product.prices.push({
        publicPrice: "",
        marginPercentage: "",
        profitAmount: "",
      });
    },
    removeVariant(index) {
      if (index > 0) {
        this.product.prices.splice(index, 1);
      }
    },
    calculateProfit(index) {
      const price = this.product.prices[index];
      if (this.product.costValue > 0 && price.publicPrice > 0) {
        price.profitAmount = price.publicPrice - this.product.costValue;
        price.marginPercentage = (
          (price.profitAmount / price.publicPrice) *
          100
        ).toFixed(2);
      } else {
        price.marginPercentage = 0;
        price.profitAmount = 0;
      }
    },
    saveLote(lote) {
      const formattedLote = {
        id: lote.id,
        name: lote.lote,
        caducidad: lote.caducidad,
        cantidad: lote.sucursales.reduce((total, sucursal) => total + Number(sucursal.existencia), 0),
        sucursales: lote.sucursales.map(sucursal => ({
          ...sucursal,
          existencia: Number(sucursal.existencia),
          reserva: Number(sucursal.reserva)
        }))
      };

      // Si es edición, actualizar lote existente
      const index = this.lotes.findIndex(l => l.id === lote.id);
      if (index !== -1) {
        this.lotes.splice(index, 1, formattedLote);
      } else {
        // Si es nuevo, agregar al array
        this.lotes.push(formattedLote);
      }
      
      this.showLoteForm = false;
      this.currentLote = null;
    },
    handleEdit(lote) {
      this.currentLote = {
        id: lote.id,
        lote: lote.name,
        caducidad: lote.caducidad,
        sucursales: lote.sucursales.map(sucursal => ({
          ...sucursal,
          existencia: String(sucursal.existencia),
          reserva: String(sucursal.reserva)
        }))
      };
      this.showLoteForm = true;
    },
  },
  watch: {
    "product.publicPrice": function () {
      this.calculateProfit();
    },
    'product.batchesAndExpiration'(newValue) {
      if (!newValue) {
        this.lotes = []; // Limpiar lotes si se desactiva la opción
      }
    }
  },
};
</script>

<style scoped></style>
