<template>
  <v-app>
    <v-container>
      <v-row>
        <div class="mt-5 px-5" style="width: 100%">
          <v-col cols="12" md="6" class="d-flex flex-column" v-if="!isEditing">
            <h2
              class="font-weight-bold mb-2 text-h6"
              style="margin: 0; flex-grow: 1"
            >
              Nuevo cliente
            </h2>
            <p class="ma-0 text-start">Dar de alta un nuevo cliente</p>
          </v-col>

          <v-form v-model="validForm" ref="clientForm">
            <v-col cols="12" md="6" class="d-flex flex-column">
              <div class="bgcards pa-2 rounded-pill">
                <v-tabs
                  v-model="tab"
                  grow
                  color="primary"
                  hide-slider
                  density
                  class="rounded-pill pa-0"
                  height="50"
                  background-color="bgcards"
                  style="border-radius: 50px"
                >
                  <v-tab
                    class="text-none text-body-1 pa-0"
                    :class="{
                      'font-weight-bold': tab === 0,
                      'text-none': tab !== 0,
                    }"
                    style="flex-grow: 1; border-radius: 50px"
                  >
                    <div
                      class="rounded-pill px-6 py-3"
                      style="background-color: white"
                    >
                      Datos básicos
                    </div>
                  </v-tab>

                  <v-tab
                    class="text-none text-body-1 pa-0"
                    :class="{
                      'font-weight-bold': tab === 1,
                      'text-none': tab !== 1,
                    }"
                    style="flex-grow: 1; border-radius: 50px"
                  >
                    <div
                      class="rounded-pill px-6 py-3"
                      style="background-color: white"
                    >
                      Dirección
                    </div>
                  </v-tab>
                </v-tabs>
              </div>

              <!-- Contenido de las pestañas -->
              <v-tabs-items v-model="tab" class="mt-5">
                <v-tab-item>
                  <v-card
                    class="py-5 px-2 bgcards rounded-xl"
                    elevation="2"
                    style="width: 100%"
                  >
                    <v-card-text class="text-start black--text">
                      <!-- Nombre y teléfono -->

                      <pat-input
                        v-model="client.as"
                        label="Alias (Opcional)"
                        placeholder="Nombre corto para identificar a tu cliente"
                        class="mb-5"
                      />
                      <pat-input
                        v-model="client.nombre_completo"
                        label="Nombre completo"
                        placeholder="Nombre completo"
                        class="mb-5"
                        :rules="[rules.required]"
                      />

                      <pat-input
                        label="Teléfono"
                        v-model="client.telefono_contacto"
                        placeholder="55 5555 5555"
                        class="mb-5"
                        :maxlength="10"
                        :isNumber="true"
                        :rules="phoneRules"
                      />

                      <pat-input
                        type="email"
                        label="Correo electrónico (opcional)"
                        v-model="client.correo_electronico"
                        placeholder="ejemplo@correo.com"
                        class="mb-5"
                        :rules="emailRules"
                      />

                      <pat-select
                        label="Precio aplicable"
                        placeholder="Selecciona el precio aplicable"
                        class="mb-5"
                        :items="precio_aplicables"
                        v-model="client.precio_aplicable"
                        :rules="[rules.required]"
                      />

                      <pat-input
                        label="Comentarios"
                        textarea
                        placeholder="Comentarios"
                        v-model="client.comentarios"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    class="py-5 px-2 bgcards rounded-xl"
                    elevation="2"
                    style="width: 100%"
                  >
                    <v-card-text class="text-start black--text">
                      <!-- Dirección -->
                      <p class="ma-0 text-start mb-5">
                        La dirección de envío no es obligatoria para dar de alta
                        un nuevo cliente.
                      </p>

                      <pat-input
                        v-model="client.direccion.street"
                        :label="streetLabel"
                        placeholder="Ingresa calle y número"
                        class="mb-5"
                        :rules="facturacionRules"
                      />
                      <pat-input
                        v-model="client.direccion.interiorNumber"
                        label="Número interior (Opcional)"
                        type="number"
                        placeholder="Ej. 1"
                        class="mb-5"
                      />
                      <pat-select
                        v-model="client.direccion.colony"
                        label="Colonia"
                        class="mb-5"
                        placeholder="Selecciona una colonia"
                        :items="colonys"
                        :rules="facturacionRules"
                      />
                      <pat-select
                        v-model="client.direccion.city"
                        label="Ciudad"
                        class="mb-5"
                        placeholder="Selecciona una ciudad"
                        :items="citys"
                        :rules="facturacionRules"
                      />
                      <pat-select
                        v-model="client.direccion.delegation"
                        label="Delegación o Municipio"
                        class="mb-5"
                        placeholder="Selecciona una delegación"
                        :items="delegations"
                        :rules="facturacionRules"
                      />
                      <pat-select
                        v-model="client.direccion.zipCode"
                        label="Código postal"
                        type="number"
                        class="mb-5"
                        placeholder="Selecciona un código postal"
                        :items="zipCodes"
                        :rules="facturacionRules"
                      />

                      <span class="textlabel--text">¿Requiere facturas?</span>
                      <v-radio-group v-model="client.requiere_factura">
                        <v-radio label="Sí" :value="true" />
                        <v-radio label="No" :value="false" />
                      </v-radio-group>

                      <div v-if="client.requiere_factura">
                        <pat-input
                          v-model="client.facturacion.companyName"
                          label="Razón social"
                          placeholder="Ingresa razón social"
                          class="mb-5"
                          :rules="facturacionRules"
                        />
                        <pat-select
                          v-model="client.facturacion.useCFDI"
                          label="Uso de CFDI"
                          class="mb-5"
                          type="number"
                          placeholder="Selecciona código postal"
                          :items="useCFDIOptions"
                          :rules="facturacionRules"
                        />
                        <pat-input
                          v-model="client.facturacion.rfc"
                          label="RFC"
                          placeholder="Ingresa RFC"
                          class="mb-5"
                          :rules="facturacionRules"
                        />
                        <pat-select
                          v-model="client.facturacion.TaxRegime"
                          label="Régimen fiscal"
                          class="mb-5"
                          placeholder="Selecciona un régimen fiscal"
                          :items="taxRegimes"
                          :rules="facturacionRules"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <!-- Botón para guardar -->
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column items-center justify-center"
            >
              <v-btn
                :disabled="!validForm"
                class="py-6 text-none text-body-1"
                :color="validForm ? 'primary' : 'grey darken-2'"
                block
                rounded
                @click="handleSubmit"
              >
                {{ buttonText }}
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column items-center justify-center"
              v-if="isEditing"
            >
              <v-btn
                text
                class="text-none primary--text my-2 text-body-1 font-weight-bold text-decoration-underline"
                @click="deleteClient"
                style="width: 100%"
              >
                Eliminar cliente
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              class="d-flex flex-column justify-center"
              v-if="isPhoneDuplicate"
            >
              <div
                class="d-flex align-center red lighten-5 py-3 px-5 rounded-xl mb-5"
              >
                <v-icon color="error" class="mr-2" size="40"
                  >mdi-alert-circle</v-icon
                >
                <span class="error--text">
                  Ya hay una cliente asociado a este teléfono, asegurate que
                  está bien escrito.
                </span>
              </div>
            </v-col>
          </v-form>
        </div>

        <!-- Modal Cliente creado con éxito -->
        <div class="text-center">
          <v-dialog
            v-model="dialogClieCreatedSuccessfully"
            max-width="400"
            persistent
            content-class="custom-dialog-content"
          >
            <div class="position-relative">
              <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
                <!-- Botón de cierre flotante -->
                <v-btn
                  outlined
                  color="primary"
                  class="py-4 border-3"
                  icon
                  style="
                    position: absolute;
                    top: -40px;
                    right: -10px;
                    border-radius: 50%;
                    border-width: 3px;
                    background-color: #fff;
                  "
                  @click="closeDialogClientCreatedSuccessfully"
                >
                  <v-icon color="primary">mdi-close</v-icon>
                </v-btn>

                <!-- Contenido principal -->
                <v-card-title
                  class="text-h5 font-weight-bold text-center light-green--text"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                    padding-bottom: 24px;
                  "
                >
                  <v-icon size="80" class="mb-4 mx-auto" color="light-green">
                    mdi-check-decagram-outline
                  </v-icon>
                  Cliente creado con éxito
                </v-card-title>
              </v-card>
            </div>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {
  createClient,
  getClient,
  updateClient,
  deleteClient,
} from "../services/auth";

export default {
  data() {
    return {
      tab: 0,
      validForm: false,
      isEditing: false,
      dialogClieCreatedSuccessfully: false,
      precio_aplicables: ["Publico en general", "Cliente frecuente"],
      originalPhoneNumber: null,
      clientContacts: [
        5555555555, 1234567890, 9876543210, 4567891234, 6789123456, 2345678901,
        7891234567, 3456789012, 8901234567, 5678901234,
      ],
      colonys: ["Colonia 1", "Colonia 2", "Colonia 3"],
      citys: ["Ciudad 1", "Ciudad 2", "Ciudad 3"],
      delegations: ["Delegación 1", "Delegación 2", "Delegación 3"],
      zipCodes: ["12345", "67890", "11223"],
      taxRegimes: [
        "Persona Física con Actividad Empresarial",
        "Régimen General de Ley Personas Morales",
        "Régimen Simplificado de Confianza",
        "Incorporación Fiscal",
      ],
      useCFDIOptions: [
        { text: "G01 - Adquisición de mercancías", value: "G01" },
        {
          text: "G02 - Devoluciones, descuentos o bonificaciones",
          value: "G02",
        },
        { text: "G03 - Gastos en general", value: "G03" },
        { text: "I01 - Construcciones", value: "I01" },
        {
          text: "I02 - Mobiliario y equipo de oficina por inversiones",
          value: "I02",
        },
        { text: "I03 - Equipo de transporte", value: "I03" },
        { text: "I04 - Equipo de cómputo y accesorios", value: "I04" },
        {
          text: "I05 - Dados, troqueles, moldes, matrices y herramental",
          value: "I05",
        },
        { text: "I06 - Comunicaciones telefónicas", value: "I06" },
        { text: "I07 - Comunicaciones satelitales", value: "I07" },
        { text: "I08 - Otra maquinaria y equipo", value: "I08" },
        {
          text: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
          value: "D01",
        },
        {
          text: "D02 - Gastos médicos por incapacidad o discapacidad",
          value: "D02",
        },
        { text: "D03 - Gastos funerales", value: "D03" },
        { text: "D04 - Donativos", value: "D04" },
        {
          text: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
          value: "D05",
        },
        { text: "D06 - Aportaciones voluntarias al SAR", value: "D06" },
        { text: "D07 - Primas por seguros de gastos médicos", value: "D07" },
        {
          text: "D08 - Gastos de transportación escolar obligatoria",
          value: "D08",
        },
        {
          text: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
          value: "D09",
        },
        {
          text: "D10 - Pagos por servicios educativos (colegiaturas)",
          value: "D10",
        },
        { text: "P01 - Por definir", value: "P01" },
      ],
      client: {
        id: 1,
        as: "",
        nombre_completo: "",
        telefono_contacto: "",
        correo_electronico: "",
        precio_aplicable: "",
        comentarios: "",
        direccion: {
          street: "",
          interiorNumber: "",
          colony: "",
          city: "",
          delegation: "",
          zipCode: "",
        },
        facturacion: {
          companyName: "",
          useCFDI: "",
          rfc: "",
          TaxRegime: "",
        },
        requiere_factura: false,
      },
      rules: {
        required: (value) => !!value || "Este campo es obligatorio.",
      },
    };
  },
  computed: {
    streetLabel() {
      return this.client.requiere_factura ? "Calle" : "Calle y número";
    },
    buttonText() {
      if (this.tab === 0) {
        return "Continuar";
      }
      return this.isEditing ? "Guardar cambios" : "Crear cliente";
    },
    facturacionRules() {
      return this.client.requiere_factura ? [this.rules.required] : [];
    },
    isPhoneDuplicate() {
      let flag;

      if (!this.isEditing) {
        flag = this.clientContacts.includes(Number(this.client.telefono_contacto));
      } else if (this.client.telefono_contacto !== this.originalPhoneNumber) {
        flag = this.clientContacts.includes(Number(this.client.telefono_contacto));
      }
      return flag;
    },
    phoneRules() {
      return [
        (v) => !!v || "Este campo es obligatorio.",
        (v) => /^\d{10}$/.test(v) || "El número celular debe tener 10 dígitos.",
        (v) => {
          // Condicionales para verificar si el teléfono ya está registrado
          if (!this.isEditing) {
            if (this.clientContacts.includes(Number(v))) {
              return "Ya existe un cliente asociado."; // Mensaje de error si ya existe
            }
          } else if (this.client.telefono_contacto !== this.originalPhoneNumber) {
            if (this.clientContacts.includes(Number(v))) {
              return "Ya existe un cliente asociado."; // Mensaje de error si ya existe
            }
          }

          return true;
        },
      ];
    },
    emailRules() {
      return [
        (v) =>
          v === "" ||
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
          "Por favor, ingresa un correo electrónico válido.",
      ];
    },
  },
  methods: {
    async initClientData() {
      if (this.isEditing) {
        const response = await getClient(this.$route.params.id);
        if (response.success) {
          this.client = {
            id: response.cliente.id,
            nombre_completo: response.cliente.nombre_completo,
            as: response.cliente.apodo,
            telefono_contacto: response.cliente.telefono_contacto,
            correo_electronico: response.cliente.correo_electronico,
            precio_aplicable: response.cliente.precio_aplicable,
            comentarios: response.cliente.comentarios,
            direccion: {
              street: response.cliente.calle,
              interiorNumber: response.cliente.numero_interior,
              colony: response.cliente.colonia,
              city: response.cliente.ciudad,
              delegation: response.cliente.delegacion,
              zipCode: response.cliente.codigo_postal,
            },
            facturacion: {
              companyName: response.cliente.razon_social,
              useCFDI: response.cliente.uso_cfdi,
              rfc: response.cliente.rfc,
              TaxRegime: response.cliente.regimen_fiscal,
            },
            requiere_factura: response.cliente.requiere_factura,
          };
        } else {
          this.$toast({
            message: "Ocurrió un error al obtener el cliente",
            color: "error",
            timeout: 2000,
          });
          this.isFinished = true;
          this.$router.push({ name: "clients" });
        }
      }
    },
    handleSubmit() {
      if (this.tab === 0) {
        this.tab = 1;
        return;
      }

      if (!this.$refs.clientForm.validate()) {
        console.log("Formulario inválido");
        return;
      }

      if (this.isEditing) {
        this.updateClient();
      } else {
        this.createClient();
      }
    },
    async createClient() {
      const response = await createClient(
        this.client.nombre_completo,
        this.client.as,
        this.client.telefono_contacto,
        this.client.correo_electronico,
        this.client.precio_aplicable,
        this.client.comentarios,
        this.client.direccion,
        this.client.facturacion,
        this.client.requiere_factura
      );

      if (response.success) {
        this.dialogClientCreatedSuccessfully = true;
        this.$toast({
          message: "Cliente creado correctamente",
          color: "success",
          timeout: 2000,
        });

        this.$router.push({ name: "clients" });
      } else {
        this.$toast({
          message: "Ocurrió un error al crear el cliente",
          color: "error",
          timeout: 2000,
        });
      }
    },
    async updateClient() {
      const response = await updateClient(
        this.$route.params.id,
        this.client.nombre_completo,
        this.client.as,
        this.client.telefono_contacto,
        this.client.correo_electronico,
        this.client.precio_aplicable,
        this.client.comentarios,
        this.client.direccion,
        this.client.facturacion,
        this.client.requiere_factura
      );

      if (response.success) {
        this.isFinished = true;
        this.$toast({
          message: "Cliente actualizado correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "clients" });
      } else {
        this.$toast({
          message: "Ocurrió un error al actualizar el cliente",
          color: "error",
          timeout: 2000,
        });
      }
    },
    closeDialogClientCreatedSuccessfully() {
      this.dialogClieCreatedSuccessfully = false;
    },
    async deleteClient() {
      const response = await deleteClient(this.$route.params.id);
      if (response.success) {
        this.isFinshed = true;
        this.$toast({
          message: "Cliente eliminado correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "clients" });
      } else {
        this.$toast({
          message: "Ocurrió un error al eliminar el cliente",
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
  mounted() {
    this.isEditing = this.$route.name === "edit-client";
    this.initClientData();
  },
};
</script>

<style>
.custom-dialog-content {
  overflow: visible !important;
}
.v-tabs-slider {
  border-radius: 50px;
}
</style>
