<template>
  <v-app>
    <v-container fluid class="white">
      <v-row>
        <!-- Formulario: Paso 1 - Ingreso de número -->
        <div v-if="step === 1" class="mt-12">
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              :src="require('@/assets/pat-pos-logo.png')"
              alt="PAT POS Logo"
              class="mx-auto mt-5"
              contain
              max-width="146px"
            />
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-center">
            <v-card class="bgcards rounded-xl mx-5" elevation="2">
              <v-card-title class="d-flex justify-center align-center">
                <h3
                  class="text-h5 font-weight-bold mb-2"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                  "
                >
                  Recupera tu contraseña
                </h3>
                <p
                  class="text-body-1 text-center"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                  "
                >
                  Ingresa tu número asociado a PAT para recibir un código y
                  recuperar tu contraseña.
                </p>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" @submit.prevent="sendVerificationCode">
                  <pat-input
                    label="Número teléfono"
                    placeholder="55 5555 5555"
                    v-model="phoneNumber"
                    :rules="phoneRules"
                    :maxlength="10"
                    :isNumber="true"
                  />
                  <br />
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    block
                    @click="sendVerificationCode"
                    rounded
                  >
                    Enviar
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </div>

        <!-- Paso 2: Código de verificación -->
        <v-row v-if="step === 2">
          <verification-code
            :phoneNumber="phoneNumber"
            @otp-validated="handleOtpValidated"
            :isRecovery="true"
          >
            <template #title>
              <span class="text-primary">Verifica tu identidad</span>
            </template>
            <template #description>
              <p class="text-body-1 mb-0">
                Hemos enviado un código al número
                <strong>{{ phoneNumber }}</strong
                >. Ingresa el código para continuar con la recuperación de tu
                contraseña.
              </p>
            </template>
          </verification-code>
        </v-row>

        <!-- Paso 3: Nueva contraseña -->
        <div v-if="step === 3" class="mt-2">
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              :src="require('@/assets/pat-pos-logo.png')"
              alt="PAT POS Logo"
              class="mx-auto"
              contain
              max-width="146px"
            />
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-center">
            <v-card
              class="bgcards rounded-xl mx-5"
              elevation="2"
              min-height="416px"
            >
              <v-card-title class="d-flex justify-center align-center">
                <h3
                  class="text-h5 font-weight-bold mb-2 text-center"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                  "
                >
                  Crea una nueva contraseña
                </h3>
                <p
                  class="text-body-1 text-center"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                  "
                >
                  Ingresa una nueva contraseña segura y confírmala para
                  completar la recuperación de tu cuenta.
                </p>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" @submit.prevent="resetPassword">
                  <pat-input
                    class="mt-4"
                    label="Crea contraseña nueva"
                    type="password"
                    placeholder="********"
                    v-model="password"
                    :rules="passwordRules"
                  />
                  <div
                    class="mb-5"
                    style="font-size: 10px; line-height: 12px; margin-top: 5px"
                  >
                    <span
                      :class="{
                        'green--text': passwordValidations.minLength,
                        'black--text': !passwordValidations.minLength,
                      }"
                    >
                      Mínimo 8 caracteres </span
                    ><br />
                    <span
                      :class="{
                        'green--text': passwordValidations.upperCase,
                        'black--text': !passwordValidations.upperCase,
                      }"
                    >
                      Incluye al menos 1 mayúscula </span
                    ><br />
                    <span
                      :class="{
                        'green--text': passwordValidations.lowerCase,
                        'black--text': !passwordValidations.lowerCase,
                      }"
                    >
                      Incluye al menos 1 minúscula </span
                    ><br />
                    <span
                      :class="{
                        'green--text': passwordValidations.specialChar,
                        'black--text': !passwordValidations.specialChar,
                      }"
                    >
                      Contenga al menos 1 carácter especial </span
                    ><br />
                  </div>
                  <pat-input
                    class="mt-4"
                    label="Confirmar contraseña"
                    type="password"
                    placeholder="********"
                    v-model="confirmPassword"
                    :rules="confirmPasswordRules"
                  />
                  <br />
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    block
                    @click="resetPassword"
                    rounded
                  >
                    Restablecer contraseña
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {forgotPassword, resetPassword} from "@/services/auth";

export default {
  name: "create-account",
  data() {
    return {
      valid: false,
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      step: 1, // Inicia en el paso 1
      passwordValidation: "",
      otp: null
    };
  },
  computed: {
    phoneRules() {
      return [
        (v) => !!v || "Número celular es requerido",
        (v) => /^\d{10}$/.test(v) || "El número celular debe tener 10 dígitos",
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length >= 8 || "Debe tener al menos 8 caracteres",
        (v) => /[A-Z]/.test(v) || "Debe incluir al menos una mayúscula",
        (v) => /[a-z]/.test(v) || "Debe incluir al menos una minúscula",
        (v) =>
          /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
          "Debe contener al menos un carácter especial",
      ];
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "Confirmación de contraseña es requerida",
        (v) => v === this.password || "Las contraseñas no coinciden",
      ];
    },
    passwordValidations() {
      return {
        minLength: this.password.length >= 8,
        upperCase: /[A-Z]/.test(this.password),
        lowerCase: /[a-z]/.test(this.password),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(this.password),
      };
    },
  },
  methods: {
    async sendVerificationCode() {
      if (this.valid) {
        const response = await forgotPassword(this.phoneNumber);
        if (response.success) {
          this.step = 2;
        } else {
          this.$toast({
            message: response.error,
            color: "error",
            timeout: 2000,
          });
        }
      }
    },
    handleOtpValidated(isValid) {
      if (isValid) {
        this.otp = isValid;
        this.step = 3;
      } else {
        this.$toast.error(
          "Código de verificación incorrecto. Intenta de nuevo."
        );
      }
    },
    async resetPassword() {
      const response = await resetPassword(this.phoneNumber, this.otp, this.password);
      if (response.success) {
        this.$toast({
          message: "Contraseña restablecida con éxito",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "login" });
      } else {
        this.$toast({
          message: response.error,
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-dialog-content {
  overflow: visible !important;
}
</style>
