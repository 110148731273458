<template>
  <v-container
    class="text-center pa-5"
    v-if="productos && productos.length == 0"
  >
    <!-- Icono Superior -->
    <div v-if="step === 1">
      <v-row justify="center" class="my-8">
        <v-col cols="12">
          <v-img
            :src="require('@/assets/inventario.png')"
            contain
            max-height="170"
            alt="Icono de productos"
            class="mb-3"
          ></v-img>
          <h2 class="font-weight-bold primary--text">
            Agrega productos a tu inventario
          </h2>
        </v-col>
      </v-row>

      <v-row justify="center" class="mb-6">
        <!-- Registrar producto -->
        <v-col cols="12" md="8" class="mb-4">
          <v-card
            outlined
            class="pa-4 text-center"
            elevation="2"
            @click="openBarCode"
          >
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" md="12" class="d-flex align-center">
                <v-icon size="36" class="mr-5" color="black">
                  mdi-barcode-scan
                </v-icon>
                <div>
                  <h3 class="font-weight-bold text-start">
                    Registra un producto
                  </h3>
                  <p class="text-body-2 text-start">
                    Agrega productos, escanea el código de barras o ingresa su
                    información
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- Importar productos -->
        <v-col cols="12" md="8">
          <v-card outlined class="pa-4 text-center" elevation="2">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" md="12" class="d-flex align-center">
                <v-icon size="36" class="mr-5" color="black">
                  mdi-file-import-outline
                </v-icon>
                <div>
                  <h3 class="font-weight-bold text-start">Importa productos</h3>
                  <p class="text-body-2 text-start">
                    Completa nuestra plantilla con tus productos y cárgalos de
                    forma masiva
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Botón de asistencia -->
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-btn
            rounded
            block
            outlined
            color="primary"
            class="py-4 border-3"
            style="border-width: 3px"
            @click="openPatAssistance"
          >
            <v-icon left>mdi-help-circle-outline</v-icon>
            Asistencia PAT
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <barcode-scanner v-if="step == 2" @scanned="handleScannedCode" />
  </v-container>

  <div fluid v-else>
    <!-- Formulario -->
    <div v-if="step === 1">
      <!-- Surcursales -->
      <div class="mx-5 mt-8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- Sucursales -->
          <pat-select
            class="mb-4"
            placeholder="Sucursales"
            :items="sucursales"
            :rules="[rules.required]"
            v-model="selectedSucursal"
          />
          <pat-input
            :maxlength="40"
            class="mb-4"
            label="Código de barras"
            placeholder="Buscar código, SKU, nombre o escanea"
            v-model="product.BarCode"
            :rules="[]"
            appendIcon="mdi-barcode-scan"
            @click:append="scanBarcode"
          />
        </v-form>

        <!-- Categorías -->
        <div class="mb-5">
          <v-col cols="12">
            <v-row>
              <v-chip-group
                mandatory
                outlined
                class="d-flex flex-row overflow-auto"
                style="white-space: nowrap"
              >
                <v-chip
                  v-for="(category, index) in categories"
                  :key="index"
                  outlined
                  class="d-flex flex-column align-center justify-start"
                  style="
                    height: 100px;
                    width: 80px;
                    margin-right: 8px;
                    padding: 0;
                    text-align: center;
                  "
                >
                  <div
                    class="d-flex flex-column align-center justify-center pa-1 pb-0"
                    style="width: 100%; height: 100%; overflow: hidden"
                  >
                    <!-- Contenedor para la imagen -->
                    <div
                      class="d-flex align-center justify-center"
                      style="width: 100%; height: 70px; overflow: hidden"
                    >
                      <v-img
                        :src="category.image"
                        contain
                        style="
                          max-width: 100%;
                          max-height: 100%;
                          object-fit: contain;
                        "
                      />
                    </div>
                    <!-- Contenedor para el texto -->
                    <p
                      class="font-weight-medium pa-0 ma-0 mt-2 px-1"
                      style="
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                      "
                    >
                      {{ category.name }}
                    </p>
                  </div>
                </v-chip>
              </v-chip-group>
            </v-row>
          </v-col>
        </div>
      </div>

      <!-- subcategorias  -->
      <!-- <div class="mb-5 mx-5">
        <v-col cols="12">
          <v-row>
            <v-chip-group mandatory outlined>
              <v-chip
                v-for="(subcategory, index) in subcategories"
                :key="index"
                outlined
              >
                <v-img
                  :src="subcategory.image"
                  contain
                  style="height: 25px; width: 25px"
                />

                <strong style="font-size: 18px"> {{ subcategory.name }}</strong>
              </v-chip>
            </v-chip-group>
          </v-row>
        </v-col>
      </div> -->

      <!-- Seleccionar todos -->
      <div class="mb-5" style="overflow-x: hidden; width: 100%">
        <v-row class="pa-2 bgcards no-gutters" style="width: 100%">
          <v-col cols="auto">
            <v-checkbox
              v-model="selectAll"
              class="d-flex align-items-center"
              label="Seleccionar todos"
              @change="toggleSelectAll"
            />
          </v-col>

          <div class="flex-grow-1"></div>

          <!-- Botón de filtro -->
          <v-col cols="auto">
            <v-btn icon left class="primary--text mr-2" @click="toggleOptions">
              <v-icon size="35px">mdi-filter-variant</v-icon>
            </v-btn>
          </v-col>

          <!-- Botón de ordenamiento -->
          <v-col cols="auto">
            <v-btn
              icon
              class="primary--text d-flex mr-3"
              @click="toggleOrdenamiento"
            >
              <v-icon size="35px">mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <transition name="fade">
        <div class="mb-2" v-if="showOptions">
          <v-row class="pa-2 bgcards d-flex" style="width: 100%">
            <v-list-item-group color="primary" dense>
              <v-list-item
                v-for="(opcion, i) in opciones"
                :key="i"
                @click="handleOptionClick(opcion)"
              >
                <v-list-item-icon>
                  <v-icon class="black--text" v-text="opcion.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content class="d-flex" style="flex-grow: 1">
                  <v-list-item-title v-text="opcion.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-row>
        </div>
      </transition>

      <transition name="fade">
        <div class="mb-2" v-if="showOrdenamiento">
          <v-row class="pa-2 bgcards d-flex" style="width: 100%">
            <v-list-item-group color="primary" dense class="w-100">
              <v-list-item
                v-for="(opcion, i) in ordenamiento"
                :key="i"
                class="d-flex"
              >
                <v-list-item-icon>
                  <v-icon class="black--text" v-text="opcion.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content class="d-flex" style="flex-grow: 1">
                  <v-list-item-title v-text="opcion.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-row>
        </div>
      </transition>

      <!-- Contenido adicional para "Ver por categoría" -->
      <v-row
        class="d-flex mx-10 mt-2"
        v-if="isCategorySelected"
        justify="space-between"
      >
        <p class="ma-0 pa-0 text-body-1 font-weight-bold">
          {{ categories[0].name }}
        </p>
        <v-btn
          text
          color="primary"
          class="ma-0 pa-0 text-decoration-underline caption"
          style="align-items: flex-start"
        >
          Ver todos
        </v-btn>
      </v-row>

      <!-- productos  -->
      <div class="mb-5 d-flex flex-column align-items-center justify-center">
        <v-checkbox
          v-for="(product, index) in productos"
          :key="index"
          v-model="selectedProducts"
          :value="product"
          outlined
          class="bgcards px-3 py-4 mb-3"
          @change="checkIfAllSelected"
        >
          <template v-slot:label>
            <v-row class="no-gutters d-flex align-items-center justify-center">
              <v-col
                cols="3"
                class="d-flex flex-column align-items-center justify-center pa-1"
                style="width: 90px; height: 90px; position: relative"
              >
                <v-card
                  flat
                  class="d-flex flex-column align-center justify-center pa-0"
                  style="width: 90px; height: 90px; position: relative"
                >
                  <v-img
                    :src="product.imagen"
                    :class="[
                      'caption text-center mt-2',
                      product.es_compra_pat ? 'mt-3' : 'mt-0',
                    ]"
                  />
                  <!-- Texto de descuento flotante en la parte superior -->
                  <div
                    v-if="product.es_compra_pat"
                    class="white--text deep-purple lighten-2 text-center rounded-t-lg rounded-b-0"
                    style="
                      position: absolute;
                      left: 0;
                      top: 0;
                      font-size: 8px;
                      width: 100%;
                      box-sizing: border-box;
                      z-index: 4;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    Compra en PAT
                  </div>
                </v-card>
              </v-col>

              <v-col
                cols="6"
                class="d-flex flex-column align-items-center justify-center pa-1"
              >
                <p
                  class="ma-0 pa-0 text-caption black--text font-weight-bold"
                  style="
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ product.nombre_producto }}
                </p>

                <p class="ma-0 pa-0 text-caption black--text">
                  {{ product.descripcion }}
                </p>
                <strong class="primary--text" style="font-size: 20px">
                  {{ product.precio }}
                </strong>
              </v-col>

              <v-col
                cols="3"
                class="d-flex flex-column align-center justify-center pa-1"
              >
                <div
                  class="rounded-lg pa-1"
                  style="
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    text-align: center;
                  "
                  :style="{
                    border:
                      product.cantidad <= 0
                        ? '1px solid red'
                        : product.cantidad <= product.min
                        ? '1px solid yellow'
                        : '1px solid green',
                  }"
                >
                  <p
                    class="ma-0 caption"
                    :class="
                      product.cantidad <= 0
                        ? 'red--text'
                        : product.cantidad <= product.min
                        ? 'yellow--text'
                        : 'green--text'
                    "
                  >
                    {{ product.cantidad }}
                  </p>
                  <p
                    class="ma-0 ml-1 caption"
                    :class="
                      product.cantidad <= 0
                        ? 'red--text'
                        : product.cantidad <= product.min
                        ? 'yellow--text'
                        : 'green--text'
                    "
                  >
                    {{ product.unidad_venta }}
                  </p>
                </div>

                <p
                  :class="[
                    'caption text-center mt-2',
                    product.es_activo ? 'purple--text' : 'text-grey',
                  ]"
                >
                  <strong>{{
                    product.es_activo ? "Activo" : "Inactivo"
                  }}</strong>
                </p>
              </v-col>
            </v-row>
          </template>
        </v-checkbox>
      </div>

      <!-- Btn flotante  -->
      <v-btn
        rounded
        color="primary"
        class="d-flex align-center justify-center py-6 pr-0"
        style="
          position: fixed;
          bottom: 20px;
          right: 20px;
          border-radius: 28px;
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
          z-index: 3;
        "
        @click="toggleMenu"
      >
        <span class="font-weight-medium mr-3" style="font-size: 16px">
          Administrar
        </span>

        <div
          class="primary d-flex align-center justify-center"
          style="width: 55px; height: 55px; border-radius: 50%"
        >
          <v-icon size="24px" color="white">mdi-note-edit-outline</v-icon>
        </div>
      </v-btn>
    </div>
    <div>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        top
        transition="slide-x-reverse-transition"
        :style="{ zIndex: 9999, right: '10px', top: '10px', position: 'fixed' }"
      >
        <v-card>
          <v-list dense color="#454854" dark>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-plus-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registrar nuevo producto</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- Más opciones... -->
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-cart-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Agregar productos al carrito</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-file-document-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Actualizar inventario</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-printer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Imprimir/Exportar inventario</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <barcode-scanner v-if="step == 3" @scanned="handleScannedCodeInventory" />
  </div>
</template>

<script>
import { useUserStore } from "../stores/userStore";
import { getSucursales, getProductos } from "@/services/auth";

export default {
  data() {
    return {
      userStore: useUserStore(),
      modalOpen: false,
      step: 1,
      valid: false,
      selectedProduct: null,
      selectedProducts: [],
      selectAll: false,
      selectedSucursal: null,
      showOptions: false,
      showOrdenamiento: false,
      isCategorySelected: false,
      menu: false,
      menuPosition: { x: 0, y: 0 },
      product: {
        BarCode: "",
      },
      showCategoryOptions: false,
      categories: [
        { name: "Medicamento", image: require("@/assets/advil.png") },
        { name: "Botiquín", image: require("@/assets/curitas.png") },
        { name: "Vitaminas", image: require("@/assets/vitaminas.png") },
        { name: "Dermatológico", image: require("@/assets/dermatologico.png") },
        { name: "Alimentos", image: require("@/assets/alimentos.png") },
        { name: "Belleza", image: require("@/assets/belleza.png") },
      ],
      subcategories: [
        { name: "Cuidados del Bebe", image: require("@/assets/jaloma.png") },
        { name: "Alimentos para Bebe", image: require("@/assets/gerber.png") },
      ],
      opciones: [
        { icon: "mdi-dns-outline", text: "Ver por categoría" },
        { icon: "mdi-cart-heart", text: "Productos comprados en PAT" },
        { icon: "mdi-toggle-switch-outline", text: "Productos Activos" },
        { icon: "mdi-toggle-switch-off-outline", text: "Productos Inactivos" },
      ],
      ordenamiento: [
        { icon: "mdi-alphabetical-variant", text: "Alfabeticamente" },
        {
          icon: "mdi-format-annotation-minus",
          text: "Menor a mayor edistencia",
        },
        { icon: "mdi-package-variant-closed-plus", text: "Recién comprados" },
        { icon: "mdi-toggle-switch-outline", text: "Activos/Inactivos" },
      ],
      sucursales: [
        { text: "Sucursal 1", value: 1 },
        { text: "Sucursal 2", value: 2 },
        { text: "Sucursal 3", value: 3 },
      ],
      productos: [],
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        minLength: (value) =>
          (value && value.length >= 8) || "Debe tener al menos 8 caracteres",
        maxLength: (value) =>
          (value && value.length <= 20) ||
          "Debe tener como máximo 20 caracteres",
        pattern: (value) =>
          /^(\d|-)+$/.test(value) || "Solo se permiten números y guiones",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
    };
  },
  watch: {
    selectedProducts(newVal) {
      // Sincroniza el estado de "Seleccionar todos" si se seleccionan o desmarcan manualmente todos los productos
      this.selectAll = newVal.length === this.productos.length;
    },
    selectedSucursal(newValue) {
      this.userStore.setSucursal(newValue);
      this.loadProductos();
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.selectAll) {
        // Si se selecciona "Seleccionar todos", agregar todos los productos a selectedProducts
        this.selectedProducts = [...this.productos];
      } else {
        // Si se desmarca "Seleccionar todos", limpiar selectedProducts
        this.selectedProducts = [];
      }
    },
    toggleMenu(event) {
      const btnRect = event.target.getBoundingClientRect();
      this.menuPosition.x = btnRect.left; // Posición del botón
      this.menuPosition.y = btnRect.top - 190; // Ajustar para desplegar arriba
      this.menu = !this.menu;
    },
    openPatAssistance() {
      console.log("Abriendo asistencia PAT...");
    },
    openBarCode() {
      this.step = 2;
    },
    checkIfAllSelected() {
      // Verificar si todos están seleccionados
      this.selectAll = this.selectedProducts.length === this.productos.length;
    },
    handleScannedCode(code) {
      if (code === false) {
        console.log("El usuario eligió ingresar el código manualmente.");
        this.$router.push({ name: "new-product-g" });
      } else {
        console.log("Código escaneado:", code);
        //Todo: Hacer que new-product-G reciba un código de barras para pasarle la info
        this.$router.push({ name: "new-product-g" });
      }
    },
    handleScannedCodeInventory(code) {
      if (code === false) {
        console.log("El usuario eligió ingresar el código manualmente.");
        this.step = 1;
      } else {
        console.log("Código escaneado:", code);
        this.product.BarCode = code;
        this.step = 1;
      }
    },
    onFormUpdate() {
      if (this.$refs.form && this.$refs.form.validate()) {
        console.log("Formulario válido", {
          selectedSucursal: this.selectedSucursal,
          product: this.product,
        });
      } else {
        console.log("Formulario inválido, Buscando producto");
      }
    },
    detalle() {
      this.$router.push({ name: "detail-inventory" });
    },

    scanBarcode() {
      console.log("Escaneando código de barras...");
      this.step = 3;
    },
    toggleOptions() {
      this.showOrdenamiento = false;
      this.showOptions = !this.showOptions;
    },
    toggleOrdenamiento() {
      this.showOptions = false;
      this.showOrdenamiento = !this.showOrdenamiento;
    },
    handleOptionClick(opcion) {
      this.showOptions = false; // Cierra el menú de opciones
      // Verifica si la opción seleccionada es "Ver por categoría"
      this.isCategorySelected = opcion.text === "Ver por categoría";
    },
    handleAdminClick() {
      this.menu = !this.menu;
    },
    async loadProductos() {
      try {
        const response = await getProductos(null, this.selectedSucursal);
        console.log('REsponse: ',response)
        if (response.success) {
          this.productos = response.productos.map(producto => ({
            id: producto.id,
            name: producto.nombre_producto,
            image: require("@/assets/vitaminas.png"), // Imagen por defecto
            desc: `${producto.marca} - ${producto.descripcion}`,
            precio: producto.PrecioProductos?.length > 0 ? 
              `$${producto.PrecioProductos[0].precio.toFixed(2)}` : 
              'Precio no disponible',
            cantidad: producto.ExistenciaProductos?.[0]?.cantidad || 0,
            unidadVenta: producto.ExistenciaProductos?.[0]?.tipo || "unidad",
            activo: true, // Podrías agregar este campo en el backend si es necesario
            compraPat: false, // Podrías agregar este campo en el backend si es necesario
            min: 5, // Podrías definir esto según tus necesidades
            categoria: producto.Categorium?.nombre || 'Sin categoría',
            codigo_barras: producto.codigo_barras,
            sucursal: producto.ExistenciaProductos?.[0]?.Sucursal?.nombre || 'Sin sucursal',
            reserva: producto.ExistenciaProductos?.[0]?.reserva || 0,
            lote: producto.ExistenciaProductos?.[0]?.lote,
            fecha_caducidad: producto.ExistenciaProductos?.[0]?.fecha_caducidad,
          }));

          console.log("Productos cargados:", this.productos);
        } else {
          this.$toast({
            message: response.mensaje || "Error al cargar los productos",
            color: "error",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error("Error cargando productos:", error);
        this.$toast({
          message: "Error al cargar los productos",
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
  async created() {
    try {
      // Obtener sucursales
      const sucursalesResponse = await getSucursales();
      if (sucursalesResponse.success) {
        this.sucursales = sucursalesResponse.sucursales.map((sucursal) => ({
          text: sucursal.nombre,
          value: sucursal.id,
        }));
        
        // Usar la sucursal del store si existe
        if (this.userStore.selectedSucursal) {
          this.selectedSucursal = this.userStore.selectedSucursal;
        } else if (this.sucursales.length > 0) {
          // Si no hay sucursal en el store, usar la primera
          this.selectedSucursal = this.sucursales[0].value;
          this.userStore.setSucursal(this.selectedSucursal);
        }
        
        // Cargar productos con la sucursal seleccionada
        await this.loadProductos();
      } else {
        this.$toast({
          message: "Ocurrió un error al obtener las sucursales",
          color: "error",
          timeout: 2000,
        });
      }

      // Obtener productos
      const productosResponse = await getProductos(); // Asume que esta función está implementada
      if (productosResponse.success) {
        this.productos = productosResponse;
      } else {
        this.$toast({
          message: "Ocurrió un error al obtener los productos",
          color: "error",
          timeout: 2000,
        });
        console.log("Error al obtener productos");
      }
    } catch (error) {
      this.$toast({
        message: "No se pudo conectar con el servidor",
        color: "error",
        timeout: 2000,
      });
      console.error("Error en la solicitud:", error);
    }
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* En el contenedor raíz */
.some-container {
  overflow: visible !important;
  position: relative;
}
/* Aseguramos que el menú se monte fuera del flujo normal */
.v-menu__content {
  position: fixed !important; /* Se asegura de que el contenido no se desplace */
  top: 20px;
  right: 20px;
  z-index: 9999;
}
</style>
