<template>
  <v-container fluid>
    <!-- Título y descripción -->
    <v-row class="px-5 pt-5 w-100" no-gutters>
      <div class="d-flex justify-between" style="width: 100%">
        <p>Ingresa la información del producto</p>
        <v-spacer />
        <v-btn
          :color="producto.es_activo ? 'deep-purple lighten-1' : 'grey darken-1'"
          outlined
          rounded
          dense
          style="padding: 0 0 0 12px; display: flex; margin-right: 8px"
          @click="toggleisActive()"
        >
          <span class="mr-1 text-none text-body-1">{{
            producto.es_activo ? "Activo" : "Inactivo"
          }}</span>
          <v-icon size="35">
            {{ producto.es_activo ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
        </v-btn>
      </div>
    </v-row>

    <!-- Formulario -->
    <v-form v-model="valid">
      <!-- Información General -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Información general
          </v-card-title>
          <v-card-text>
            <!-- Código de barras -->
            <div class="mb-2">
              <label class="d-flex align-center font-weight-bold mb-3">
                Código de barras
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Ingrese o escanee el código de barras del producto.</span
                  >
                </v-tooltip>
              </label>
              <pat-input
                placeholder="0 000000 000000 (Opcional)"
                v-model="producto.codigo_barras"
                appendIcon="mdi-barcode-scan"
              />
            </div>

            <!-- SKU -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                SKU <span class="textgrey--text"> &nbsp;(Opcional)</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agrega el SKU del producto (Opcional).</span>
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="producto.sku" />
            </div>

            <!-- Nombre del producto -->
            <pat-input
              label="Nombre del producto"
              placeholder="Ej. Jarabe"
              v-model="producto.nombre_producto"
              :rules="[rules.required]"
              class="mb-5"
            />

            <!-- Marca -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Marca <span class="textgrey--text"> &nbsp;(Opcional)</span>
              </label>
              <pat-input placeholder="(Opcional)" v-model="producto.marca" />
            </div>

            <!-- Categoría -->
            <div class="mb-4">
              <label class="d-flex align-center font-weight-bold mb-3">
                Categoría
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Seleccione la categoría correspondiente al producto.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona una categoría"
                :items="categoriesFilter"
                v-model="producto.categoria_id"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
              />
            </div>

            <!-- Subcategoría -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Subcategoría
                <span class="textgrey--text"> &nbsp;(Opcional)</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Seleccione una subcategoría específica, si aplica.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona subcategoría (Opcional)"
                :items="subcategories"
                v-model="producto.subcategoria_id"
                item-text="text"
                item-value="value"
                :disabled="!producto.categoria_id || subcategories.length === 0"
              />
            </div>

            <!-- Producto controlado -->
            <v-checkbox
              v-model="producto.es_controlado"
              label="Producto controlado o requiere receta médica"
              :true-value="true"
              :false-value="false"
            />

            <!-- Descripción -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Descripción
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agregue información adicional sobre el producto.</span>
                </v-tooltip>
              </label>
              <pat-input
                textarea
                placeholder="Agrega información adicional a tu producto (Opcional)"
                v-model="producto.descripcion"
              />
            </div>

            <!-- Proveedor o fabricante -->
            <pat-input
              label="Proveedor o fabricante"
              placeholder="(Opcional)"
              v-model="producto.proveedor_fabricante"
              class="mb-5"
            />

            <!-- Unidad de venta -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de venta
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Seleccione la unidad de venta del producto.</span>
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de venta"
                :items="unitsOfSale"
                item-text="nombre"
                item-value="nombre"
                v-model="producto.unidad_venta"
                :rules="[rules.required]"
              />
            </div>

            <!-- Contenido -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Contenido por caja
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agrega contenido total de la caja.</span>
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="producto.contenido_por_caja" />
            </div>

            <!-- Unidad de medida -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de medida
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Seleccione la unidad de medida del producto.</span>
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de medida"
                :items="unitsOfMeasurement"
                v-model="producto.unidad_medida"
                :rules="[rules.required]"
              />
            </div>

            <!-- Cantidad -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Cantidad en gramos
                <span class="textgrey--text"> &nbsp;(Opcional)</span>
              </label>
              <pat-input placeholder="(Opcional)" v-model="producto.cantidad" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Especificaciones -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Especificaciones
          </v-card-title>
          <v-card-text>
            <!-- Lista de sustancias activas -->
            <div
              v-for="(substance, index) in producto.sustancias"
              :key="index"
              class="mb-5"
            >
              <!-- Input de Sustancia Activa -->
              <pat-input
                class="mb-2"
                label="Sustancia Activa"
                placeholder="Naranja en polvo"
                v-model="substance.nombre"
              >
                <template #append>
                  <v-icon
                    class="cursor-pointer"
                    @click="removeActiveSubstance(index)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </pat-input>

              <!-- Concentración y Unidad -->
              <div class="d-flex justify-space-between">
                <div class="mr-2">
                  <pat-input
                    type="number"
                    label="Concentración"
                    placeholder="500"
                    v-model="substance.concentracion"
                    class="mt-1"
                    style="min-width: 100px"
                    :rules="[rules.isNumber]"
                  />
                </div>
                <div>
                  <pat-select
                    label="Unidad"
                    :items="unityOptions"
                    placeholder="MG"
                    v-model="substance.unidad"
                    class="mt-1"
                    style="min-width: 100px"
                  />
                </div>
              </div>
            </div>

            <!-- Opción "No Aplica" -->
            <v-checkbox
              v-model="producto.no_aplica"
              label="No aplica"
              :true-value="true"
              :false-value="false"
              class="mt-2"
              hide-details
            />

            <!-- Botón para agregar sustancia activa -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addActiveSubstance"
            >
              <v-icon
                left
                color="primary"
                class="x align-center"
                style="font-size: 24px"
              >
                mdi-plus
              </v-icon>
              <span class="text-decoration-underline"
                >Agregar Sustancia Activa</span
              >
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Carga de Imagen del Producto -->
      <v-col cols="12" md="12" class="d-flex" @click="openModalUploadPhoto">
        <productUploadCard
          icon="mdi-image-plus-outline"
          text="Da clic aquí para tomar una fotografía o subir un archivo"
        ></productUploadCard>
      </v-col>

      <!-- Variantes -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Variantes
          </v-card-title>
          <v-card-text>
            <!-- Botón de agregar variante -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addVariant"
            >
              <v-icon left color="primary">mdi-plus</v-icon>
              <span class="text-decoration-underline">Agregar Variante</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Impuestos -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Impuestos
          </v-card-title>
          <v-card-text>
            <!-- checkbox para impuestos -->
            <v-checkbox
              v-model="producto.cobrar_impuestos"
              label="Cobrar impuestos del producto"
              :true-value="true"
              :false-value="false"
              hide-details
              class="mb-5"
            />

            <!-- Selectores de IVA e IEPS -->
            <div class="d-flex justify-space-between mt-2">
              <div class="mr-2">
                <pat-select
                  label="IVA"
                  :items="ivaOptions"
                  placeholder="Selecciona"
                  v-model="producto.iva"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
              <div>
                <pat-select
                  label="IEPS"
                  :items="iepsOptions"
                  placeholder="Selecciona"
                  v-model="producto.ieps"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Facturación -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Facturación
          </v-card-title>
          <v-card-text>
            <!-- Clave SAT -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Clave SAT
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Busca por palabras clave de tu producto.</span>
                </v-tooltip>
              </label>
              <pat-input
                placeholder="Busca por palabras clave de tu producto"
                v-model="producto.clave_sat"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Modal Agregar Variante -->
      <div class="text-center">
        <v-dialog
          v-model="dialogAddVariant"
          max-width="400"
          persistent
          content-class="custom-dialog-content"
        >
          <div class="position-relative">
            <!-- Tarjeta principal del contenido -->
            <v-card
              class="rounded-xl pa-6"
              style="
                background-color: #f4f4fa;
                position: relative;
                overflow: visible;
              "
            >
              <!-- Botón de cierre flotante -->
              <v-btn
                outlined
                color="primary"
                class="py-4 border-3"
                icon
                style="
                  position: absolute;
                  top: -40px;
                  right: 0px;
                  border-radius: 50%;
                  border-width: 3px;
                  background-color: #fff;
                "
                @click="closedialogAddVariant"
              >
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>

              <v-card-title>
                <h3 class="text-h5 font-weight-bold text-center mb-5">
                  Agregar variante
                </h3>
              </v-card-title>
              <v-card-text>
                <pat-select
                  label="Opción"
                  class="my-5"
                  placeholder="Selecciona una categoría"
                  :items="categoriesFilter"
                  v-model="producto.variant.option"
                />
                <pat-input
                  label="Descripción"
                  placeholder="Ej. Jarabe"
                  v-model="producto.variant.description"
                  class="mb-5"
                />
                <!-- Mensaje de error -->
                <v-col cols="12" class="d-flex justify-center" v-if="showError">
                  <div
                    class="d-flex align-center red lighten-5 pa-2 rounded-xl"
                  >
                    <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                    <span class="error--text">
                      Completa los campos obligatorios para continuar
                    </span>
                  </div>
                </v-col>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="py-6"
                  @click="saveVariant"
                  color="grey primary"
                  dark
                  block
                  rounded
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-dialog>
      </div>

      <v-card-actions class="justify-center">
        <v-btn
          :disabled="!valid"
          class="py-6"
          @click="submitForm"
          :color="valid ? 'primary' : 'grey darken-1'"
          block
          rounded
        >
          Continuar
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- Modal existencia y precio producto -->
    <div class="text-center">
      <v-dialog
        v-model="dialogProductInformation"
        max-width="400"
        persistent
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: -10px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeDialogProductInformation"
            >
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>

            <!-- Imagen y título -->
            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
                padding-bottom: 24px;
              "
            >
              <v-img
                src="../assets/info.png"
                contain
                max-height="100"
                max-width="100"
                alt="Icono de productos"
                class="mb-4 mx-auto"
              ></v-img>
              ¿Quieres continuar agregando la existencia y precio del producto?
            </v-card-title>

            <v-card-actions
              class="d-flex flex-column items-center align-center"
            >
              <v-btn
                @click="goto('branch-no-lot')"
                class="py-4"
                color="primary"
                dark
                block
                rounded
              >
                Continuar
              </v-btn>

              <v-col cols="12" md="8" class="d-flex justify-center mt-3">
                <p
                  class="primary--text text-decoration-underline font-weight-bold"
                  style="cursor: pointer"
                  @click="closeDialogAndSave"
                >
                  En otro momento
                </p>
              </v-col>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { createProducto, getCategorias, getSubcategorias } from "@/services/auth";

export default {
  data() {
    return {
      producto: {
        es_activo: true,
        nombre_producto: "",
        proveedor_fabricante: "",
        marca: "",
        categoria_id: "",
        subcategoria_id: "",
        es_controlado: null,
        descripcion: "",
        contenido_por_caja: "",
        cantidad: "",
        codigo_barras: "",
        sku: "",
        unidad_venta: "",
        unidad_medida: "",
        no_aplica: false,
        sustancias: [],
        cobrar_impuestos: false,
        iva: "",
        ieps: "",
        clave_sat: "",
        variant: {
          option: "",
          description: "",
        },
      },
      categoriesFilter: [],
      subcategories: [],
      unitsOfSale: [
        { "clave": "H87", "nombre": "Pieza" },
        { "clave": "ACT", "nombre": "Actividad" },
        { "clave": "KGM", "nombre": "Kilogramo" },
        { "clave": "GRM", "nombre": "Gramo" },
        { "clave": "MGM", "nombre": "Miligramo" },
        { "clave": "LTR", "nombre": "Litro" },
        { "clave": "MLT", "nombre": "Mililitro" },
        { "clave": "MTR", "nombre": "Metro" },
        { "clave": "XBX", "nombre": "Caja" },
        { "clave": "E48", "nombre": "Unidad de Servicio" },
        { "clave": "EA", "nombre": "Elemento" },
        { "clave": "KT", "nombre": "Kit" },
        { "clave": "SET", "nombre": "Conjunto" },
        { "clave": "E51", "nombre": "Trabajo" },
        { "clave": "MTK", "nombre": "Metro cuadrado" },
        { "clave": "PR", "nombre": "Par" },
        { "clave": "BB", "nombre": "Caja base" },
        { "clave": "XPK", "nombre": "Paquete" },
        { "clave": "A9", "nombre": "Tarifa" },
        { "clave": "MON", "nombre": "Mes" },
        { "clave": "HUR", "nombre": "Hora" },
        { "clave": "DAY", "nombre": "Día" },
        { "clave": "AB", "nombre": "Paquete a granel" },
        { "clave": "xun", "nombre": "Unidad" },
        { "clave": "XLT", "nombre": "Lote" }
      ],
      unitsOfMeasurement: ["Gramos", "Kilogramos", "Litros", "Mililitros"],
      unityOptions: ["MG", "ML", "G", "L"],
      ivaOptions: ["16%", "8%", "0%"],
      iepsOptions: ["Selección 1", "Selección 2", "Selección 3"],
      valid: false,
      showError: false,
      dialogAddVariant: false,
      dialogAddPhoto: false,
      dialogProductInformation: false,
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
    };
  },
  methods: {
    toggleisActive() {
      this.producto.es_activo = !this.producto.es_activo;
    },
    addActiveSubstance() {
      this.producto.sustancias.push({
        nombre: "",
        concentracion: "",
        unidad: "",
      });
    },
    removeActiveSubstance(index) {
      console.log("Eliminando sustancia activa en índice:", index);
      this.producto.sustancias.splice(index, 1);
    },
    addVariant() {
      this.dialogAddVariant = true;
    },
    closedialogAddVariant() {
      this.dialogAddVariant = false;
      this.showError = false;
    },
    saveVariant() {
      const { option, description } = this.producto.variant;
      if (!option || !description) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.dialogAddVariant = false;
    },
    openModalUploadPhoto() {
      this.dialogAddPhoto = true;
    },
    openGallery() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    takePhoto() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    openFileExplorer() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.producto.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      if (this.valid) {
        this.dialogProductInformation = true;
      }
    },
    closeDialogProductInformation() {
      this.dialogProductInformation = false;
    },
    goto(route) {
      if (route === 'branch-no-lot') {
        this.saveProduct('branch-no-lot');
      }
    },
    async saveProduct(redirectTo = 'home') {
      try {
        const response = await createProducto(this.producto);
        
        if (response.success) {
          // Mostrar mensaje de éxito
          this.$emit("show-snackbar", {
            text: "Producto creado exitosamente",
            color: "success",
          });
          
          // Redirigir según la opción seleccionada
          if (redirectTo === 'branch-no-lot') {
            this.$router.push({ 
              name: "branch-no-lot",
              params: { productId: response.producto.id }
            });
          } else {
            this.$router.push({ name: "home" });
          }
        } else {
          // Mostrar mensaje de error
          this.$emit("show-snackbar", {
            text: response.error || "Error al crear el producto",
            color: "error",
          });
        }
      } catch (error) {
        console.error("Error al guardar el producto:", error);
        this.$emit("show-snackbar", {
          text: "Error al crear el producto",
          color: "error",
        });
      }
      this.dialogProductInformation = false;
    },
    async loadCategorias() {
      try {
        const response = await getCategorias();
        if (response.success) {
          // Transformamos las categorías al formato necesario
          this.categoriesFilter = response.categorias.map(cat => ({
            text: cat.nombre,
            value: cat.id
          }));
        } else {
          this.$emit("show-snackbar", {
            text: response.error || "Error al cargar las categorías",
            color: "error",
          });
        }
      } catch (error) {
        console.error("Error cargando categorías:", error);
        this.$emit("show-snackbar", {
          text: "Error al cargar las categorías",
          color: "error",
        });
      }
    },
    async loadSubcategorias(categoria_id) {
      if (!categoria_id) {
        this.subcategories = [];
        return;
      }

      try {
        const response = await getSubcategorias(categoria_id);
        if (response.success) {
          // Transformamos las subcategorías al formato necesario
          this.subcategories = response.subcategorias.map(subcat => ({
            text: subcat.nombre,
            value: subcat.id
          }));
        } else {
          // Si no hay subcategorías, limpiamos el array
          if (response.error.includes('No se encontraron subcategorías')) {
            this.subcategories = [];
            return;
          }
          
          this.$emit("show-snackbar", {
            text: response.error || "Error al cargar las subcategorías",
            color: "error",
          });
        }
      } catch (error) {
        console.error("Error cargando subcategorías:", error);
        this.$emit("show-snackbar", {
          text: "Error al cargar las subcategorías",
          color: "error",
        });
      }
    },
    closeDialogAndSave() {
      this.saveProduct('home');
    },
  },
  watch: {
    // Observar cambios en la categoría seleccionada
    'producto.categoria_id': {
      handler(newCategoriaId) {
        // Limpiar subcategoría seleccionada
        this.producto.subcategoria_id = '';
        // Cargar nuevas subcategorías
        this.loadSubcategorias(newCategoriaId);
      }
    }
  },
  async mounted() {
    this.$emit("update:titulo", "Nuevo producto");
    this.$emit("update:onBack", () => {
      this.$router.push({ name: "home" });
    });
    
    // Cargar las categorías al montar el componente
    await this.loadCategorias();
  },
};
</script>
<style scoped>
.custom-dialog-content {
  overflow: visible !important;
}
.custom-dialog-overlay {
  overflow: visible !important;
}
</style>
