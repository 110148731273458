<template>
  <div style="width: 100%">
    <v-card class="pa-4 bgcards rounded-xl elevation-2" style="width: 100%">
      <div
        class="text-center d-flex flex-column align-center justify-center pa-2"
        style="border: 1px solid #000; height: 200px; width: 100%"
      >
        <!-- Carrusel de imágenes o el icono y texto de carga -->
        <v-card
          v-if="files.length > 0"
          class="rounded-xl elevation-0"
          style="width: 100%; height: 100%"
        >
          <v-carousel max-height="250px" style="width: 100%; height: 100%">
            <v-carousel-item v-for="(file, index) in files" :key="index">
              <v-img :src="file.preview" cover />
            </v-carousel-item>
          </v-carousel>
        </v-card>

        <v-card
          v-else
          class="rounded-xl elevation-0 d-flex align-center justify-center text-center bgcards"
          :height="height"
          @click="openModal"
        >
          <v-card-text class="text-center py-auto">
            <v-icon class="mx-a" :size="iconSize">{{ icon }}</v-icon>
            <span class="text-body-1 tertiary--text">
              <br />
              {{ text }}
            </span>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
    <!-- Modal para seleccionar opciones -->
    <v-dialog v-model="dialogAddPhoto" max-width="400">
      <v-card class="rounded-xl">
        <v-list class="rounded-xl" color="#454854">
          <!-- Opción: Galería de fotos -->
          <v-list-item @click="openGallery" class="white--text">
            <v-list-item-icon>
              <v-icon color="white">mdi-image-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Galería de fotos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Opción: Tomar foto -->
          <v-list-item @click="takePhoto" class="white--text">
            <v-list-item-icon>
              <v-icon color="white">mdi-camera-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tomar foto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Opción: Seleccionar archivo -->
          <v-list-item @click="openFileExplorer" class="white--text">
            <v-list-item-icon>
              <v-icon color="white">mdi-folder-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Seleccionar archivo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- Input oculto para seleccionar la imagen o cualquier archivo -->
    <input
      ref="fileInput"
      type="file"
      multiple
      accept="image/*"
      capture="camera"
      style="display: none"
      @change="handleFileChange"
    />
  </div>
</template>

<script>
export default {
  name: "ProductUploadCard",
  props: {
    icon: {
      type: String,
      default: "mdi-image-outline", // Icono predeterminado
    },
    text: {
      type: String,
      default: "Da clic para subir la imagen del producto", // Texto predeterminado
    },
    height: {
      type: String,
      default: "170px", // Altura predeterminada del card
    },
    iconSize: {
      type: String,
      default: "40px", // Tamaño predeterminado del icono
    },
    isImage: {
      type: Boolean,
      default: true, // Define si solo se deben permitir imágenes
    },
  },
  data() {
    return {
      dialogAddPhoto: false, // Estado para mostrar el modal
      files: [], // Aquí se almacenarán las imágenes seleccionadas
    };
  },
  methods: {
    openModal() {
      this.dialogAddPhoto = true; // Abre el modal
    },
    openGallery() {
      // Cuando el usuario elige la galería de fotos
      this.dialogAddPhoto = false;
      this.$refs.fileInput.accept = "image/*"; // Solo imágenes
      this.$refs.fileInput.click(); // Abre el explorador de archivos
    },
    takePhoto() {
      // Cuando el usuario elige tomar una foto
      this.dialogAddPhoto = false;
      alert("Tomando foto");
      this.$refs.fileInput.accept = "image/*"; // Solo imágenes
      this.$refs.fileInput.capture = "camera"; // Abre la cámara
      this.$refs.fileInput.click(); // Abre el explorador de archivos
    },
    openFileExplorer() {
      // Cuando el usuario selecciona "Seleccionar archivo"
      this.dialogAddPhoto = false;
      this.$refs.fileInput.accept = ".pdf, .docx, .xlsx"; // Permitir PDFs y Office
      this.$refs.fileInput.click(); // Abre el explorador de archivos
    },
    handleFileChange(event) {
      const selectedFiles = event.target.files;
      if (selectedFiles.length > 0) {
        // Limpiar archivos previos
        this.files = [];

        // Procesar cada archivo seleccionado
        Array.from(selectedFiles).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              file,
              preview: e.target.result, // Generar vista previa de la imagen
            });
          };
          reader.readAsDataURL(file);
        });

        this.$emit("files-uploaded", this.files); // Emitir el evento con los archivos cargados
      }
    },
  },
};
</script>

<style scoped></style>
