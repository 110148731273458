<template>
  <v-container class="d-flex justify-center align-center" fill-height>
    <v-dialog v-model="modalOpen" max-width="400px" persistent>
      <div class="d-flex flex-column align-center pa-5">
        <div
          class="d-flex justify-center align-center mb-4 rounded-lg"
          style="
            width: 80%;
            height: 250px;
            background: #fff;
            border: 4px solid #a864f0;
          "
        >
          <!-- Aquí irá el componente de escaneo -->
          <v-btn @click="simulateScan" color="secondary" class="mt-4"
            >Simular escaneo</v-btn
          >
        </div>

        <p class="text-center white--text mb-5" style="width: 10rem">
          Apunta la cámara al código de barras
        </p>
        <v-icon size="48" color="white" class="my-3">mdi-barcode-scan</v-icon>

        <!-- Botón para ingresar código manualmente -->
        <v-btn
          block
          color="primary"
          dark
          rounded
          @click="handleManualInput"
          class="py-6 text-none text-body-1"
        >
          <v-icon size="28" left class="mr-3">mdi-gesture-tap-button</v-icon>
          Ingresar código manualmente
        </v-btn>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "barcode-scanner",
  props: {},
  data() {
    return {
      modalOpen: true,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    // Simula el escaneo de un código de barras
    simulateScan() {
      const simulatedBarcode = "123456789"; // Código de barras simulado
      this.closeModal(); // Cierra el modal después del "escaneo"
      this.$emit("scanned", simulatedBarcode); // Emite el código simulado
    },
    // Emite false al hacer clic en "Ingresar código manualmente"
    handleManualInput() {
      this.closeModal(); // Cierra el modal
      this.$emit("scanned", false); // Emite false indicando que no se escaneó ningún código
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog {
  box-shadow: none !important;
}
</style>
