<template>
  <v-app>
    <v-app-bar
      app
      class="menu-background"
      dark
    >
      <v-btn icon>
        <img
          src="@/assets/logo-g.svg"
          alt="Logo PAT"
          class="ma-0 pa-0 white--text ml-10 pl-10"
          style="height: 35px"
        />
      </v-btn>

      <div class="flex-grow-1"></div>

      <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
        <v-badge
          color="lime"
          overlap
          class="position-absolute"
          content="3"
          location="top-end"
        ></v-badge>
      </v-btn>

      <!-- Icono de menú hamburguesa -->
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Menú lateral -->
    <v-navigation-drawer
      v-if="$route.name !== 'login'  && $route.name !== 'create-account'"
      v-model="drawer"
      app
      class="menu-background rounded-l-xxl"
      right
      width="280"
      transition="slide-x-reverse-transition"
    >
      <!-- Header del menú -->
      <v-list-item class="py-6 d-flex justify-start">
        <v-avatar>
          <v-icon size="45" color="lime">mdi-account-circle</v-icon>
        </v-avatar>
        <div class="ml-2">
          <div class="text-body-1 font-weight-bold white--text">
            {{ userName }}
          </div>
          <div class="white--text mb-5">{{SustituirRol(rol)}}</div>
          <a
            text
            class="lime--text text-decoration-underline text-start"
            @click="goto('configuration')"
            v-if="rol == 2"
            >Configuración</a
          >
        </div>
      </v-list-item>

      <!-- Opciones del menú -->
      <v-list dense>
        <v-list-group v-model="group1" no-action>
          <template v-slot:activator>
            <v-icon size="35" class="white--text mr-2"
              >mdi-storefront-outline</v-icon
            >

            <v-list-item class="d-flex align-center px-0">
              <v-list-item-title class="white--text"
                >Mi negocio</v-list-item-title
              >
            </v-list-item>
          </template>
          <v-list-item
            v-if="isAllowed('inventory')"
            @click="goto('inventory')"
            class="d-flex align-center ml-3"
          >
            <v-list-item-title class="white--text"
              >Inventario</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="isAllowed('promotions')"
            @click="goto('promotions')"
            class="d-flex align-center ml-3  disabled"
            disabled
          >
            <v-list-item-title class="gray--text lighten-4"
              >Promociones</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="isAllowed('branches')"
            @click="goto('branches')"
            class="d-flex align-center ml-3"
          >
            <v-list-item-title class="white--text"
              >Sucursales y/o almacenes</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="isAllowed('employees')"
            @click="goto('employees')"
            class="d-flex align-center ml-3"
          >
            <v-list-item-title class="white--text">Empleados</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAllowed('clients')"
            @click="goto('clients')"
            class="d-flex align-center ml-3"
          >
            <v-list-item-title class="white--text">Clientes</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group v-model="group2" no-action class="mt-5">
          <template v-slot:activator>
            <v-img src="@/assets/logo-pat.svg" width="30" class="mr-2"></v-img>

            <v-list-item class="d-flex align-center px-0">
              <v-list-item-title class="white--text">PAT</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item @click="goto('home')" class="d-flex align-center ml-3">
            <v-list-item-title class="white--text">Inicio</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAllowed('requests')"
            @click="goto('requests')"
            disabled
            class="d-flex align-center ml-3  disabled"
          >
            <v-list-item-title class="gray--text lighten-4"
              >Solicitudes</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="toHelp()" class="d-flex align-center ml-3">
            <v-list-item-title class="white--text">Ayuda</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- Cerrar sesión -->
      <v-list-item
        class="logout w-100 white--text d-flex align-center"
        @click="cerrarSesion()"
      >
        <v-list-item-icon>
          <v-icon class="white--text">mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="white--text">Cerrar sesión</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { logout } from '@/services/auth';
import { useUserStore } from "@/stores/userStore";

export default {
  name: "MenuLayout",
  data: () => ({
    drawer: false,
    group1: true,
    group2: true,
    allowedRoutes: {
      inventory: [2, 3, 4, 5, 6], 
      promotions: [2, 3, 4],
      branches: [2],
      employees: [2, 3],
      sellers: [2, 7],
      clients: [2, 3, 4, 5, 6, 7],
      home: [2, 3, 4, 5, 6, 7],
      requests: [2, 3, 4],
      help: [2, 3, 4, 5, 6, 7],
    },
    roles: [
        { id: 2, name: "Admin" },
        { id: 3, name: "Manager" },
        { id: 4, name: "Supervisor" },
        { id: 5, name: "Almacenista" },
        { id: 6, name: "Cajero" },
        { id: 7, name: "Vendedor" },
      ],
  }),
  computed: {
    userStore() {
      return useUserStore();
    },
    userName() {
      return this.userStore.user?.UsuarioDetalle?.nombre;
    },
    rol() {
      return this.userStore.user?.rol_id;
    }
  },
  methods: {
    isAllowed(routeName) {
      const roles = this.allowedRoutes[routeName];
      if (!roles) return false; 
      return roles.includes(this.rol);
    },
    SustituirRol(id) {
      if (!id) return "Sin rol";
      return this.roles.find((role) => role.id === id).name || "admin";
    },
    goto(route) {
      this.$router.push({ name: route });
    },
    cerrarSesion() {
      logout();
      this.$router.push({ name: 'login' });
    },
    toast({ message, color = 'info', timeout = 3000 }) {
      this.$refs.toast.showToast({ message, color, timeout });
    },
    goBack() {
      this.$router.push('home');
    },
    toHelp(){
      const phoneNumber = 525527275447;
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=Necesito soporte técnico en PAT`;
      window.open(whatsappUrl, '_blank');
    }
  },
  async mounted() {
    await this.userStore.getUser();
  },
};
</script>

<style>
.menu-background {
  background: linear-gradient(170deg, #5e90f4, #8e47ec);
  color: white;
}

.v-list-item {
  display: flex;
  align-items: center;
}

.logout {
  position: absolute;
  bottom: 16px;
}
.v-btn{
  text-transform: none !important;
}
</style>

