<template>
  <v-app>
    <v-container>
      <v-row>
        <div class="mt-5 px-5" style="width: 100%">
          <v-form v-model="validForm" ref="employeeForm">
            <v-col cols="12" md="6" class="d-flex">
              <v-card
                class="py-5 px-2 bgcards rounded-xl"
                elevation="2"
                style="width: 100%"
              >
                <v-card-title class="d-flex justify-space-between align-center">
                  <h3 class="text-h6 font-weight-bold mb-2">
                    {{ isEditing ? 'Empleado ' + $route.params.index : 'Nuevo Empleado' }}
                  </h3>
                </v-card-title>
                <v-card-text class="text-start black--text">
                  <!-- Nombre y teléfono -->
                  <pat-input
                    v-model="employee.nombre"
                    label="Nombre completo"
                    placeholder="Nombre completo"
                    class="mb-5"
                    :rules="[rules.required]"
                  />
                  <pat-input
                    v-model="employee.telefono"
                    label="Celular de contacto"
                    placeholder="55 5555 5555"
                    class="mb-5"
                    :maxlength="10"
                    :isNumber="true"
                    :rules="[rules.required]"
                  />
                  <pat-select
                    v-model="employee.rol"
                    label="Rol"
                    placeholder="Selecciona un rol"
                    class="mb-3"
                    :items="rolesOptions"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  />

                  <div class="mb-10">
                    <label class="d-flex align-center font-weight-bold mb-3">
                      Límite de gastos en PAT
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="grey"
                            class="ml-1"
                            style="font-size: 18px"
                          >
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span
                          >Es la cantidad máxima que le autorizas a tu empleado
                          para gastar en PAT.</span
                        >
                      </v-tooltip>
                    </label>
                    <pat-input
                      type="number"
                      placeholder="$3,000.0"
                      v-model="employee.limite_compra"
                    />
                  </div>

                  <!-- Roles dinámicos -->
                  <div
                    v-for="(role, index) in employee.Sucursales"
                    :key="index"
                    class="mb-4"
                  >
                    <v-row class="w-100 my-2" no-gutters>
                      <div
                        class="d-flex justify-between align-center"
                        style="width: 100%"
                      >
                        <div class="d-flex" style="flex-grow: 1">
                          <p class="mb-2 text-h6 ma-0 mr-2">
                            Sucursal {{ index + 1 }}
                          </p>
                          <!-- Botón para eliminar rol -->
                          <v-btn
                            v-if="index > 0"
                            icon
                            color="red lighten-2"
                            outlined
                            rounded
                            dense
                            @click="removeRole(index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>

                        <div class="d-flex">
                          <!-- Botón para alternar estado -->
                          <v-btn
                            :color="
                              role.esActivo
                                ? 'deep-purple lighten-1'
                                : 'grey darken-1'
                            "
                            outlined
                            rounded
                            dense
                            style="
                              padding: 0 0 0 12px;
                              display: flex;
                              margin-right: 8px;
                            "
                            @click="toggleRoleesActivo(index)"
                          >
                            <span class="mr-1 text-none text-body-1">{{
                              role.esActivo ? "Activo" : "Inactivo"
                            }}</span>
                            <v-icon size="35">
                              {{
                                role.esActivo
                                  ? "mdi-check-circle"
                                  : "mdi-close-circle"
                              }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-row>

                    <!-- Datos del rol -->
                    <v-row>
                      <v-col cols="12">
                        <pat-select
                          v-model="role.sucursalId"
                          label="Sucursal"
                          placeholder="Selecciona una sucursal"
                          class="mb-3"
                          :items="SucursalesOptions"
                          item-text="nombre"
                          item-value="id"
                          :rules="[rules.required, rules.sucursales]"
                        />
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Botón para agregar más roles -->
                  <v-btn
                    :disabled="isPhoneDuplicate"
                    text
                    class="text-none primary--text my-2 text-body-1 font-weight-bold text-decoration-underline"
                    @click="addRole"
                    style="width: 100%"
                  >
                    + Asociar más Sucursales
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              class="d-flex flex-column justify-center"
              v-if="isPhoneDuplicate"
            >
              <div
                class="d-flex align-center red lighten-5 py-3 px-5 rounded-xl mb-5"
              >
                <v-icon color="error" class="mr-2" size="40"
                  >mdi-alert-circle</v-icon
                >
                <span class="error--text">
                  Ya hay una cuenta PAT asociada a ese número de celular,
                  asegurate que esta bien escrito, para más información
                  llamanos.
                </span>
              </div>
              <v-btn
                rounded
                block
                outlined
                color="primary"
                class="py-6 border-3 text-body-1 font-weight-bold text-none"
                style="border-width: 3px"
                @click="openPatAssistance"
              >
                <v-icon size="28" left>mdi-help-circle-outline</v-icon>
                Asistencia PAT
              </v-btn>
            </v-col>
            <!-- Botón para guardar -->
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column items-center justify-center"
            >
              <v-btn
                :disabled="!validForm"
                class="py-6 text-none text-body-1"
                :color="validForm ? 'primary' : 'grey darken-2'"
                block
                rounded
                @click="handleSubmit"
              >
                {{ isEditing ? "Guardar cambios" : "Crear empleado" }}
              </v-btn>
              <v-btn
              v-if="isEditing"
              text
              class="font-weight-bold primary--text text-none text-body-1 my-4 text-decoration-underline"
              @click="deleteEmpleado"
            >
              Eliminar Empleado
            </v-btn>
            </v-col>
          </v-form>
        </div>

        <!-- Modal Empleado creado con éxito -->
        <div class="text-center">
          <v-dialog
            v-model="dialogEmployeeCreatedSuccessfully"
            max-width="400"
            persistent
            content-class="custom-dialog-content"
          >
            <div class="position-relative">
              <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
                <!-- Botón de cierre flotante -->
                <v-btn
                  outlined
                  color="primary"
                  class="py-4 border-3"
                  icon
                  style="
                    position: absolute;
                    top: -40px;
                    right: -10px;
                    border-radius: 50%;
                    border-width: 3px;
                    background-color: #fff;
                  "
                  @click="closedialogEmployeeCreatedSuccessfully"
                >
                  <v-icon color="primary">mdi-close</v-icon>
                </v-btn>

                <!-- Contenido principal -->
                <v-card-title
                  class="text-h5 font-weight-bold text-center"
                  style="
                    white-space: normal;
                    overflow-wrap: normal;
                    word-break: normal;
                    padding-bottom: 24px;
                  "
                >
                  <v-icon size="80" class="mb-4 mx-auto" color="light-green">
                    mdi-check-decagram-outline
                  </v-icon>
                  Empleado creado con éxito
                </v-card-title>

                <v-card-text class="text-center text-body-1 black--text">
                  <p>
                    Recuerda que deberá crear su cuenta con el mismo número que
                    lo diste de alta
                  </p>
                  <p>
                    Comparte el siguiente código con él para que pueda
                    verificarla:
                  </p>
                  <span class="font-weight-bold textgrey--text">{{
                    codeVerification
                  }}</span>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="informEmployee"
                    class="py-6"
                    color="grey primary"
                    dark
                    block
                    rounded
                  >
                    <v-icon left>mdi-share-variant-outline</v-icon>
                    Avisa a tu empleado
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {getEmpleado ,updateEmpleado, createEmpleado ,getSucursales} from "@/services/auth";
import { deleteEmpleado } from "../services/auth";
export default {
  data() {
    return {
      validForm: false,
      isEditing: false,
      dialogEmployeeCreatedSuccessfully: false,
      codeVerification: 6789,
      totalEmployees: 0, // Consultar el total desde API
      SucursalesOptions: ["La Divina", "La Divina 2", "Otra"],
      rolesOptions: [
        { id: 3, name: "Manager" },
        { id: 4, name: "Supervisor" },
        { id: 5, name: "Almacenista" },
        { id: 6, name: "Cajero" },
        { id: 7, name: "Vendedor" },
      ],
      originalPhoneNumber: null,
      employeeContacts: [
        5555555555, 1234567890, 9876543210, 4567891234, 6789123456, 2345678901,
        7891234567, 3456789012, 8901234567, 5678901234,
      ],
      employee: {
        id: null,
        rol: null,
        nombre: "",
        telefono: "",
        Sucursales: [
          {
            sucursalId: "",
            role: "",
            esActivo: true,
          },
        ],
        limite_compra: "",
      },
      rules: {
        required: (value) => !!value || "Este campo es obligatorio.",
        sucursales: (value) => !this.isSucursalRepetida(value) || "Selecciona una sucursal diferente.",
      },
      isPhoneDuplicate: false,
    };
  },
  computed: {
    phoneRules() {
      return [
        (v) => !!v || "Este campo es obligatorio.",
        (v) => /^\d{10}$/.test(v) || "El número celular debe tener 10 dígitos.",
        (v) => {
          if (!this.isEditing) {
            if (this.employeeContacts.includes(Number(v))) {
              return "Ya existe una cuenta PAT.";
            }
          } else if (this.employee.telefono !== this.originalPhoneNumber) {
            if (this.employeeContacts.includes(Number(v))) {
              return "Ya existe una cuenta PAT.";
            }
          }
        },
      ];
    },
  },
  methods: {
    isSucursalRepetida(value) {
      const sucursales = this.employee.Sucursales.map((sucursal) => sucursal.sucursalId);
      console.log("Sucursales", sucursales);
      return sucursales.filter((sucursal) => sucursal == value).length > 1;
    },
    async initEmployeeData() {
      if (this.isEditing) {
        const response = await getEmpleado(this.$route.params.id);
        if(response.success){
          this.employee.nombre = response.empleado.UsuarioDetalle.nombre;
          this.employee.telefono = response.empleado.telefono;
          this.employee.rol = response.empleado.rol_id;
          this.employee.limite_compra = response.empleado.UsuarioDetalle.limite_compra;
          this.employee.Sucursales = response.empleado.Sucursales.map(sucursal => {
            return {
              sucursalId: sucursal.id,
              esActivo: sucursal.UsuarioSucursal.es_activo,
            };
          });
        } else {
          console.log("Error al obtener empleado");
          this.$toast({
            message: "Ocurrió un error al obtener el empleado",
            color: "error",
            timeout: 2000,
          });
        }
      }
    },
    handleSubmit() {
      if (!this.$refs.employeeForm.validate()) {
        console.log("Formulario inválido");
        return;
      }

      if (this.isEditing) {
        this.updateEmployee();
      } else {
        this.createEmployee();
      }
    },
    async createEmployee() {
      const response = await createEmpleado(this.employee);
      if(response.success){
        this.codeVerification = response.otp;
        this.dialogEmployeeCreatedSuccessfully = true;
      } else {
        console.log("Error al crear empleado");
        if(response.error == "Validation error"){
          this.isPhoneDuplicate = true;
        } else {
          this.$toast({
            message: "Ocurrió un error al crear el empleado",
            color: "error",
            timeout: 2000,
          });
        }
      }

    },
    async updateEmployee() {
      const response = await updateEmpleado(this.$route.params.id,this.employee);
      if(response.success){
        this.$toast({
          message: "Empleado editado con éxito",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "employees" });
      } else {
          console.log("Error al editar empleado");
          if(response.error == "Validation error"){
            this.isPhoneDuplicate = true;
          } else {
          this.$toast({
            message: "Ocurrió un error al editar el empleado",
            color: "error",
            timeout: 2000,
          });
        }
      }
    },
    async deleteEmpleado() {
      const response = await deleteEmpleado(this.$route.params.id);
      if (response.success) {
        this.isFinshed = true;
        this.$toast({
          message: "Empleado eliminado correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "employees" });
      } else {
        this.$toast({
          message: "Ocurrió un error al eliminar al empleado",
          color: "error",
          timeout: 2000,
        });
      }
    },
    toggleRoleesActivo(index) {
      this.employee.Sucursales[index].esActivo =
        !this.employee.Sucursales[index].esActivo;
    },
    addRole() {
      this.employee.Sucursales.push({
        sucursalId: "",
        esActivo: true,
      });
    },
    removeRole(index) {
      this.employee.Sucursales.splice(index, 1);
    },
    openPatAssistance() {
      console.log("Asistencia PAT...");
    },
    closedialogEmployeeCreatedSuccessfully() {
      this.dialogEmployeeCreatedSuccessfully = false;
      this.$router.push({ name: "employees" });
    },
    informEmployee() {
    const phoneNumber = this.employee.telefono;
    const otpMessage = `Tu código de verificación es: ${this.codeVerification}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(otpMessage)}`;
    window.open(whatsappUrl, '_blank');
    },
  },
  async mounted() {
    const resSucursales = await getSucursales();
    this.SucursalesOptions  = resSucursales.sucursales.map(sucursal => {
      return {
        id: sucursal.id,
        nombre: sucursal.nombre,
      };
    });
    this.isEditing = this.$route.name === "edit-employee";
    if (this.isEditing){
      this.$emit("update:titulo", "Editar empleado");
    }
    else {
      this.$emit("update:titulo", "Crear empleado");
    }
    this.initEmployeeData();
  },
};
</script>

<style>
.custom-dialog-content {
  overflow: visible !important;
}
</style>
