<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "EmptyLayout",
};
</script>

<style>
/* Estilos específicos si es necesario */
</style>
