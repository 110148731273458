<template>
<v-app>
  <component :is="layout">
    <router-view />
  </component>
  <toast-notification ref="toast" />
</v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import MenuLayout from "@/layouts/MenuLayout.vue";
import InfoBarLayout from "@/layouts/InfoBarLayout.vue";
import TransparentLayout from "@/layouts/TransparentLayout.vue";
import Vue from 'vue';
export default {
  computed: {
    layout() {
      const layout = this.$route.meta.layout;
      switch (layout) {
        case "menu":
          return MenuLayout;
        case "info-bar":
          return InfoBarLayout;
        case "transparent":
          return TransparentLayout;
        default:
          return EmptyLayout;
      }
    },
  },
  methods: {
    toast({ message, color = 'info', timeout = 3000 }) {
      this.$refs.toast.showToast({ message, color, timeout });
    }
  },
  async mounted() {
    Vue.prototype.$toast = await this.toast;
  },
};
</script>